.adminReportingContainer{
    width: 100%;
    height: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;

    .reportingCard{
        width: 100%;
        background-color: #0B132F;
        filter: drop-shadow(5px 5px 10px #000000);
        border-radius: 15px;
    }
}