@import '../../var.css';

.TopBar{
    background-color: #35125A;
    width: 100%;
    height: var(--topbarHeight);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #FFFFFF;
    padding: 0px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

#menuBars{
    margin-left: 10px;
    margin-right: 10px;
    font-size: 25px;
}

.TopBar p{
    font-size: 30px;
}
