.purchaseContainer{
    width: 100vw;
    height: 100%;
    position: relative;
    // top: 50px;
    // left: 0px;
    background-color: #0B132F;
    justify-content: flex-start;
    padding: 0px;
    overflow: hidden;
    // z-index: 2;

    .purchaseHeader{
        width: 100%;
        height: 20%;
        margin-top: 2%;
        border-top-left-radius: 100px;
        background: linear-gradient(79deg, #006BAC 19.17%, #E52E80 82.6%);
        margin-bottom: -3%;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 50%;
        }
    }
    .purchaseBody{
        width: 100%;
        height: 83%;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        background: linear-gradient(60deg, rgba(13,20,45,1) 37%, rgba(17,44,66,1) 85%);
        padding: 20px;
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        overflow-y: scroll;
        overflow-x: hidden;

        .purchaseAmountHeader{
            margin: 0px;
            color: #FFF;
            font-size: 10px;
            font-weight: 700;
            align-self: flex-start;
            margin-left: 10%;
        }
        .purchaseAmountContainer{
            width: 90%;
            height: 50px;
            border-radius: 15px;
            background-color: #FFFFFF40;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
            .purchaseAmount{
                margin: 0px;
                color: #FFF;
                text-align: center;
                font-size: 30px;
                font-weight: 700;
            }
        }
        .purchaseSummaryContainer{
            width: 90%;
            border-radius: 15px;
            background-color: #D9D9D9;
            padding: 10px;
            padding-top: 16px;
            padding-bottom: 16px;
            margin-bottom: 30px;
            .purchaseWithdrawalDate{
                margin: 0px;
                color: #0B132F;
                text-align: center;
                font-size: 13px;
                font-weight: 300;
                margin-bottom: 16px;
            }
            .purchaseSummaryPredictions{
                width: 100%;
                display: flex;
                flex-direction: row;
                .purchaseSummaryPred{
                    width: 50%;

                    .sumHeading{
                        margin: 0px;
                        color: #0B132F;
                        text-align: center;
                        font-size: 13px;
                        font-weight: 300;
                    }
                    .sumAmount{
                        margin: 0px;
                        color: #0B132F;
                        text-align: center;
                        font-size: 22px;
                        font-weight: 700;
                    }
                    .sumPercent{
                        margin: 0px;
                        text-align: center;
                        font-size: 13px;
                        font-weight: 700;
                    }
                }
            }
        }
        .randWallet{
            width: 90%;
            background-color: #FFFFFF40;
            border-radius: 15px;
            padding: 10px;
            margin-bottom: 10px;
            p{
                margin: 0px;
                color: #FFF;
                text-align: center;
                font-size: 15px;
                font-weight: 300;
            }
            .randWalletText{
                margin: 0px;
                color: #FFF;
                text-align: center;
                font-size: 20px;
                font-weight: 700;
            }
        }
        .randWalletActive{
            background-color: #D42E79;
        }
        .paymentMethodContainer{
            width: '100%';
            display: flex;
            flex-direction: row;
            margin-bottom: 50px;

            .paymentMethodItem{
                width: 25vw;
                height:84px;
                border-radius: 15px;
                background-color: #FFFFFF40;
                margin-left: 1vw;
                margin-right: 1vw;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                img{
                    width: 90%;
                    height: 50%;
                }
                p{
                    margin: 0px;
                    color: #FFF;
                    text-align: center;
                    font-size: 17px;
                    font-weight: 700;
                }
            }
            .paymentMethodItemActive{
                background-color: #D42E79;
            }
        }
        .slidingPasswordInput {
            // transition: transform 1s; /* Apply a transition for smooth animation */
            transition: transform 0.5s, opacity 0.5s, height 0.5s, visibility 0.5s;
            transform: translateY(0px); /* Initially hidden below */
          
            /* Add any other styles you want for the hidden input here */
          }
          
          /* Additional styles for the slidingPasswordInput when webauthn is false */
          .slidingPasswordInputHidden {
            // display: none !important; /* Hide the hidden input */
            transform: translateY(-80px)!important; /* Hidden position */
            opacity: 0 !important;
            height: 0px !important;
            visibility: hidden !important;
          }
          
          /* Additional styles for the slidingPasswordInput when webauthn is true */
          .slidingPasswordInput.visible {
            transform: translateY(0); /* Visible position */
            // display: block !important; /* Show the input */
            opacity: 1;
          }
    }
}