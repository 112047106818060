.transactionItem{
    display: flex;
    width: 100%;
    justify-content: flex-start;
}

.transactionItem img{
    width: 15%;
}

.transactionSection1{
    display: flex;
}

.transactionStatus{
    display: flex;
    color: white;
    align-items: center;
    flex-direction: row;
}