.newInvestHeader{
    color: #FFF;
    text-align: center;
    font-size: 35px;
    font-weight: 900;
    margin: 0px;
}

.ntiQuestionCard{
    width: 85%;
    border-radius:15px;
    background-color: #FFF;
    padding: 14px;
    .ntiCardClosedContent{
        .ntiQuestion{
            color: #000;
            font-size: 15px;
            font-weight: 900;
            margin: 0px;
        }
    }
    
}