.footer{
    width: 100%;
    // height: 50vh;
    background-color: #0B132F;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    .footerSpacer{
        width: 20%;
        height: 2px;
        background-color: #FFFFFF40;
        margin-top: 25px;
        margin-bottom: 25px;
    }

}