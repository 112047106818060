.stepper{
    width: 100%;
    height: 40px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
}

.stepperInactive{
    width: 80%;
    height: 6px;
    border-radius: 15px;
    background-color: #FFFFFF40;
    margin-top: 5px;
}

.stepperActive{
    width: 80%;
    height: 6px;
    border-radius: 15px;
    background: rgb(0,107,172);
    background: linear-gradient(90deg, rgba(0,107,172,1) 25%, rgba(229,46,128,1) 64%);
    margin-top: 5px;
}

.bioSkip{
    background-color: #FFFFFF40 !important;
}

.forgotPasswordText{
    color: #FFF;
    text-align: center;
    font-size: 15px;
    font-weight: 300;
}