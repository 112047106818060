.authQuestionText{
    color: #FFF;
    text-align: center;
    font-size: 23px;
    font-weight: 900;
    margin: 0px;
    margin-bottom: 20px;
    width: 80%;
}

.authAnswerCard{
    width: 80%;
    border-radius: 15px;
    background-color: #FFF;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    p{
        color: #000;
        font-size: 15px;
        font-weight: 900;
        margin: 0px;
    }
}

.selectedAnswer{
    background-color: #d42e79;
}