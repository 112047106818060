@import '../../var.css';

.PopupContainer{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #006BACb0;
    z-index: 9001;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Popup{
    z-index: 9002;
    width: 60%;
    /* min-height: 50%; */
    background-color: #0B132F;
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* top: 50%;
    left: 50%; */
    margin: 0px;
    border-radius: 15px;
}
.PopupHeaderBar{
    width: 100%;
    height: 64px;
    background: linear-gradient(90deg, #2A6091 0.04%, #E43180 117.78%);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}