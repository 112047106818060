.newListingAdminContainer{
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .newListingAdminSide{
        width: 49%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        .newListingAdminInfo{
            width: 90%;
            border-radius: 15px;
            background-color: #FFFFFF40;
            padding: 20px;
            .detailsHeader{
                color: #FFF;
                font-family: Roboto;
                font-size: 30px;
                font-style: normal;
                font-weight: 900;
                line-height: normal;
                margin: 0px;
                text-align: left;
            }
            .detailsHeaderDivider{
                width: 100%;
                height: 3px;
                background-color: #FFFFFF40;
                border-radius: 15px;
                margin-top: 10px;
            }
            .newListingDetailContainer{
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-top: 10px;
                p{
                    margin: 0px;
                    color: #FFF;
                    font-family: Roboto;
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }
                img{
                    height: 35px;
                    cursor: pointer;
                } 

                .newListingStatusIndicator{
                    height: 90%;
                    background-color: #FFFFFF40;
                    border-radius: 15px;
                    padding: 10px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    p{
                        color: #FFF;
                        text-align: center;
                        font-family: Roboto;
                        font-size: 30px;
                        font-style: normal;
                        font-weight: 900;
                        line-height: normal;
                    }
                }

                .newListingDetailinput{
                    max-width: 50%;
                    border: none;
                    outline: none;
                    background-color: #FFFFFF40;
                    border-radius: 15px;
                    color: #FFF;
                    text-align: center;
                    font-family: Roboto;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    margin: 0px;
                }
            }
            .detailsContainerDivider{
                width: 100%;
                height: 1px;
                border-radius: 15px;
                background-color: #FFFFFF40;
                margin-top: 10px;
            }
        }

        .newListingBannerCoinContainer{
            width: 90%;
            height: 60px;
            margin-top: 10px;
            // margin-bottom: 10px;
            display: flex;
            flex-direction: row;
            gap: 10%;

            .uploadIconContainer{
                height: 60px;
                width: 60px;
                background-color: #FFFFFF40;
                aspect-ratio: 1/1;
                border-radius: 15px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
            p{
                color: #FFF;
                font-family: Roboto;
                font-size: 25px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                margin: 0px;
            }
        }
        .newListingHeadlineContainer{
            width: 90%;
            flex-grow: 1;
            background-color: #FFFFFF40;
            border-radius: 15px;
            padding: 10px;
            .headlineHeader{
                margin: 0px;
                color: #FFF;
                font-family: Roboto;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-align: left;
                height: 5%;
            }
            .headlineArea{
                width: 100%;
                height: 95%;
                resize: none;
                background: transparent;
                border: none;
                outline: none;
                color: #FFF;
                font-family: Roboto;
                font-size: 20px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                padding: 0px;
            }
            .headlineArea::placeholder{
                color: #FFFFFF80;
                font-family: Roboto;
                font-size: 20px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }
        }

    }
}

.bottomButtonContainer{
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}