.AndroidInstallPrompt{
    background-color: #CE307A;
    position: absolute;
    bottom: 0px;
    padding-right: 5px;
    width: 100%;
    z-index: 5;
}

.AndroidPromptDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.AndroidPromptImg{
    height: 60px;
    padding-left: 10px;
}

.AndroidPromptHeader{
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 5px;
}

.AndroidPromptSub{
    font-size: 15px;
}

.AndroinPromptInstallDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.closeAndroidPrompt{
    color: white;
    padding-right: 10px;
    font-size: 25px;
}

.installButton{
    margin: 0px !important;
    height: 35px !important;
    min-height: 10px !important;
    padding: 0px;
}