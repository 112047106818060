.adminUpdatesContainer{
    width: 100%;
    height: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;

    .adminNewUpdateContainer{
        width: 78%;
        overflow: scroll;
        background: linear-gradient(60deg, rgba(13,20,45,1) 37%, rgba(17,44,66,1) 85%);
        position: absolute;
        top: 100px;
        display: flex;
        flex-direction: row;
        border: 1px solid #E52E80;
        border-radius: 15px;
    }
}