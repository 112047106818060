.listingSolutionCardContainer{
    width: 85%;
    min-width: 85%;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    .listingSolutionCardContainerHeader{
        color: #FFF;
        font-size: 20px;
        font-weight: 900;
        margin: 0px;
        margin-bottom: 10px;
        text-align: left;
        // height: 5%;
    }
}
.listingSolutionCard{
    width: 100%;
    background-color: #FFF;
    border-radius: 15px;
    border-top-left-radius: 0px;
    // height: 95%;
    flex: 1;
    display: flex;flex-direction: column;
    justify-content: flex-end;
    img{
        width: 100%;
        border-radius: 15px;
        border-top-left-radius: 0px;
        flex: 1;
        object-fit: cover;
    }
    p{
        color: #0B132F;
        text-align: center;
        font-size: 15px;
        font-weight: 300;
    }
}