.overviewTotalsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    min-height: 40% !important;
    justify-content: center;
    margin-top: -5%;
    position: relative !important;
    // background-image: url('./gauge.svg');
    // background-repeat: no-repeat;
    // background-position: 50% 4vh;
    // background-size: 72%;

    .frstcarousel {
        height: 79%;
        width: 80%;
        display: flex;
        position: absolute !important;
        top: 11%;
        z-index: 2;

        .slider-wrapper {
            overflow: hidden;
            transition: height 0.15s ease-in;
            width: 81%;
            height: 86%;
            border-radius: 80%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 11%;

            .totBody {
                position: relative;
                min-height: 22vh;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .totCircleSVG {
                    position: absolute;
                    transform: rotate(0deg);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: blue;
                    height: 99%;
                    width: 100%;
                }

                .totcircle {
                    cx: 50%;
                    cy: 50%;
                    r: 39.5%;
                    stroke-width: 2%;
                    fill: none;
                    stroke-dasharray: 462px, 102px;
                    stroke: #006CAC;
                }

                .totTitle {
                    font-size: 20px;
                    font-weight: 300;
                    line-height: 15px;
                    text-align: center;
                    // margin: 0px;
                }

                .userName {
                    font-size: 17.5px;
                    font-weight: 100;
                    line-height: 12px;
                    letter-spacing: 0em;
                    text-align: center;
                }

                .totamout {
                    font-size: 30px;
                    font-weight: 900;
                    line-height: 29px;
                    text-align: center;
                    margin: 1px;
                }
            }
        }

        .carousel-slider {
            position: relative;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            height: 100%;
            background-image: url('./gauge.svg');
            background-repeat: no-repeat;
            background-position: 55% 0% !important;
            background-size: 90% !important;

            .control-dots {
                top: 80%;
                height: 6%;
                bottom: 0;
                padding: 0;
                text-align: center;
                z-index: 1;
                width: 100%;
                left: 5px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                position: absolute;
                animation: fade 0.5s;

                .selected {
                    width: 13px !important;
                    min-height: 13px !important;
                    background: linear-gradient(to right, rgb(212, 46, 121), rgb(212, 46, 121), rgb(212, 46, 121));
                }

                .dot {
                    min-width: 5px !important;
                    border-radius: 10px !important;
                    cursor: pointer;
                    display: inline-block;
                    margin: 0 8px;
                    opacity: 1;
                    background-color: rgb(255, 255, 255);
                }

                @keyframes fade {
                    0% {
                        opacity: 0;
                    }

                    20% {
                        opacity: 0.2;
                    }

                    40% {
                        opacity: 0.4;
                    }

                    60% {
                        opacity: 0.6;
                    }

                    80% {
                        opacity: 0.8;
                    }

                    100% {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .overviewForeignObject {
        border-radius: 50%;
    }

    .carouselCont {
        width: 100%;
        height: 100;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .circleCarousel {
        width: 55vw;
        margin: 0px;

        .carousel {
            border-radius: 50%;
        }
    }

    .circleDiv {
        display: flex;
        width: 55vw;
        height: 55vw;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .circleHeader {
            margin: 0px;
            color: #FAFAFA;
            text-align: center;
            font-size: 15px;
            font-weight: 300;
        }

        .circleContent {
            margin: 0px;
            color: #FFF;
            text-align: center;
            font-size: 25px;
            font-weight: 900;
        }

        .circleFooter {
            margin: 0px;
            color: #FAFAFA;
            text-align: center;
            font-size: 12px;
            font-weight: 300;
        }

        // .circleFooter {
        //     text-transform: lowercase;
        //   }

        // .circleFooter:first-letter {
        // text-transform: uppercase;
        // }

        .circleNavigatorContainer {
            width: 50%;
            height: 15px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;

            .circleNavigatorItemActive {
                width: 10px;
                height: 10px;
                background-color: #E43180;
                border-radius: 50%;
            }

            .circleNavigatorItem {
                width: 5px;
                height: 5px;
                background-color: #D9D9D9;
                ;
                border-radius: 50%;
            }
        }
    }


}

.tabContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    position: relative;

    .selectedTabCurve {
        position: absolute;
        left: 80%;
        bottom: -1px;
    }

    .tabItemContainer {
        width: 65px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // margin-bottom: 20px;

        .tabItemName {
            color: #D1D1D1;
            text-align: center;
            font-size: 10px;
            font-weight: 500;
            margin: 0px;
        }

        .tabItem {
            width: 65px;
            height: 65px;
            border-radius: 10px;
            background: #FAFAFA;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .tabItemContainerActive {
        width: 75px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

        .tabItemName {
            color: #FFF;
            text-align: center;
            font-size: 13px;
            font-weight: 500;
            margin: 0px;
        }

        .tabItem {
            width: 75px;
            height: 85px;
            border-radius: 15px;
            background: #FAFAFA;
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
            margin-bottom: -1px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .tabItem::before {
            width: 10px;
            height: 10px;
            border-radius: 0 0 50% 50%;
            background-color: transparent;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            content: '';
            transform: rotate(-45deg);
            position: absolute;
            border: 1px solid transparent;
            border-bottom: 3px solid #FAFAFA;
            bottom: -2px;
            left: -10.5px;

        }

        .tabItem::after {
            width: 10px;
            height: 10px;
            border-radius: 0 0 50% 50%;
            background-color: transparent;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
            content: '';
            transform: rotate(45deg);
            position: absolute;
            border: 1px solid transparent;
            border-bottom: 3px solid #FAFAFA;
            bottom: -2px;
            right: -20.5px;

        }

    }

}

.tabContent {
    width: 100%;
    background-color: #FAFAFA;
    padding: 20px;
    margin-bottom: -1px;

    .noInvestmentsP {
        margin: 0px;
        color: #0B132F;
        text-align: center;
        font-size: 13px;
        font-weight: 300;
    }

    .transactionsTable {
        border-collapse: collapse;
        width: 100%;

        .dateColumn {
            width: 32%;
        }

        .companyColumn {
            width: 32%;
        }

        .amountColumn {
            width: 32%;
        }

        .statusColumn {
            width: 4%;
        }

        thead {
            color: #0B132F;
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            border-bottom: 2px solid #00000040;

            th {
                padding-bottom: 5px;
            }
        }

        tbody {
            color: #0B132F;
            font-size: 15px;
            font-weight: 300;

            tr {
                border-bottom: 1px solid #00000040;

                td {
                    padding-top: 10px;
                    padding-bottom: 10px;

                    svg {
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}

.portfolioRandContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #00000040;
    padding-bottom: 20px;
    margin-bottom: 20px;

    .portfolioRandHeader {
        margin: 0px;
        color: #0B132F;
        font-size: 15px;
        font-weight: 300;
    }

    .portfolioRandAmount {
        margin: 0px;
        color: #0B132F;
        text-align: right;
        font-size: 20px;
        font-weight: 700;
    }
}

.profileHeaderContainer {
    width: 100%;
    display: flex;
    flex-direction: row;

    .profileInfo {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        p {
            margin: 0px;
            color: #0B132F;
            font-size: 15px;
            font-weight: 700;
            text-align: left;
        }
    }

    .verifiedInfo {
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
            margin: 0px;
            color: #0B132F;
            text-align: center;
            font-size: 10px;
            font-weight: 300;
        }
    }
}

.profileInputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .profileUpdatesButtonContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
}

.portfolioLandscape {
    width: 100vw;
    background-color: #FAFAFA;
    margin-bottom: -1px;
}

.verificationCopy {
    color: #0B132F;
    text-align: center;
    font-size: 13px;
    font-weight: 300;
    margin: 0px;
    margin-bottom: 20px;
}