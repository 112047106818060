.css-select {
    width: 80%;
    height: 60px;
    border-radius: 15px;
    background: rgba(217, 217, 217, 0.25);
    margin: 1.5%;

    p {
        min-height: 3%;
        width: 99.96%;
        margin: 5px 0 0% 0;
        padding: 0px 0 0 12.5px;
        font-size: 15px;
        font-weight: 700;
        color: white;
        text-align: left;
    }

    .css-b62m3t-container {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 58%;
        border-radius: 15px;
        margin: 0%;
        display: flex;
        align-items: center;

        .css-13cymwt-control,
        .css-t3ipsp-control {
            border-radius: 15px;
            background: transparent;
            border-style: none;
            color: rgba(215, 206, 206, 0.5607843137);
            width: 100%;
            height: 10%;
            margin: -8px 0 0 0;

            .css-1fdsijx-ValueContainer {
                border-radius: 15px 0 0 15px;
                color: rgba(215, 206, 206, 0.5607843137);
                height: 99%;
                display: flex;
                align-content: center;
                margin: 0%;

                .css-1jqq78o-placeholder {
                    color: #d1c5c54b;
                    font-size: 20px;
                    font-weight: 300;
                    line-height: 23.44px;
                    text-align: left;
                    margin: 0 0 0 5px;
                }
                .css-qbdosj-Input {
                    visibility: visible;
                    flex: 1 1 auto;
                    display: inline-grid;
                    grid-template-columns: 0 min-content;
                    margin: 2px;
                    padding-bottom: 2px;
                    padding-top: 2px;
                    color: hsl(0, 0%, 20%);
                    box-sizing: border-box;
                }
            }

            .css-1hb7zxy-IndicatorsContainer {
                border-radius: 0 15px 15px 0;

                .css-1u9des2-indicatorSeparator {
                    background-color: transparent !important;
                    align-self: stretch;
                    width: 0px;
                    margin-bottom: 8px;
                    margin-top: 8px;
                    box-sizing: border-box;
                }

                .css-1xc3v61-indicatorContainer {
                    width: 2vw;
                    height: 6%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .css-tj5bde-Svg {
                        display: inline-block;
                        fill: currentColor;
                        line-height: 1;
                        stroke: currentColor;
                        stroke-width: 0;
                        background: transparent;
                        min-height: 4.5%;
                        min-width: 2vw;
                        margin-left: -0.5vw;
                    }
                }
            }
        }

        .css-t3ipsp-control {
            border-radius: 15px;
            border: none transparent !important;
            box-shadow: 0 0 0 0px transparent;

            .css-1fdsijx-ValueContainer {
                .css-1dimb5e-singleValue {
                    grid-area: 1 / 1 / 1 / 1;
                    min-width: auto;
                    overflow: hidden;
                    color: hsl(0deg 0% 80.62%);
                    margin: 0% 1.4% 0%;
                    height: 70%;
                    display: flex;
                    align-items: center;
                    font-size: 20px;
                    font-weight: 300;
                }
            }

            .css-1hb7zxy-IndicatorsContainer {
                border-radius: 0 15px 15px 0;
                background-color: transparent !important;

                .css-1u9des2-indicatorSeparator {
                    background-color: transparent !important;
                    align-self: stretch;
                    width: 0px;
                    margin-bottom: 8px;
                    margin-top: 8px;
                    box-sizing: border-box;
                }
            }
        }

        .css-1nmdiq5-menu {
            background: rgba(255, 191, 0, 0.952);
            border-radius: 15px;
            color: white;
            margin-top: 0%;
            z-index: 3;

            .css-1n6sfyn-MenuList {
                background :rgba(217, 217, 217, 0.25);
                .css-d7l1ni-option {
                    cursor: default;
                    display: block;
                    font-size: inherit;
                    user-select: none;
                    background: transparent;
                    color: inherit;
                    padding: 8px 12px;
                    box-sizing: border-box;
                    border-radius: 15px;
                    width: 97%;
                    margin-left: 1.5%;
                }
            }
        }

        .css-13cymwt-control {
            .css-1fdsijx-ValueContainer {

                .css-1dimb5e-singleValue {
                    grid-area: 1 / 1 / 1 / 1;
                    min-width: 18vw;
                    overflow: hidden;
                    color: hsl(0deg 0% 80.62%);
                    margin: 0% 1.4% 0%;
                    height: 70%;
                    display: flex;
                    align-items: center;
                    font-size: 20px;
                    font-weight: 300;
                }
            }
        }
    }
}
