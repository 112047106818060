.carousel-container{
    /* background: #f1f1f1; */
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    width: 100%;
}

.carousel-item{
    height: 20rem;
    min-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1);
    border: 1px solid white;
}

.signinForm{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40vw;
    border: 1px solid white;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;

}