

.transactionComp{
    background-color: #FFFFFF;
  width: 95%;
  margin-left: 5%;
  border-radius: 15px;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
//   padding: 5px;

  margin-right: 5%;
  p{
    color: #4f1b87;
  }
  .compSubtitle{
    margin: 0px;
  }
  .compTitle{
    margin: 0px;
    font-weight: bolder;
    font-size-adjust: 0.8;
    font-size: 45px;
    line-height: 40px;
    
  }
  .compSmall{
    font-size: 12px;
  }

  .profileReferDetails{
    display: flex;
    justify-content: space-between;
    p{
        margin: 0px;
    }
    .referralCodeCont{
        background-color: #D1427E;
        border-radius: 12px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
            color: white;
            margin: 0px;
            text-align: center;
            font-weight: bold;
            font-size: 20px;
        }
    }
  }
}