.complianceSelectionContent{
    width: 100%;
    height: 82%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .complianceSelector{
        width: 70%;
        height: 65px;
        background-color: #D9D9D940;
        border-radius: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        .complianceSelectorItem{
            width: 40%;
            height: 90%;
            background-color: #FFFFFF40;
            cursor: pointer;
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            p{
                margin: 0px;
                color: #FFF;
                text-align: center;
                font-family: Roboto;
                font-size: 30px;
                font-style: normal;
                font-weight: 900;
                line-height: normal;
            }
        }
    }
}

.complianceCont{
    background-image: url('https://wealthcdn.aions.co.za/compliance/car.png');
    pointer-events: none;
}
#AEX{
    pointer-events: auto;
}
#Entity{
    pointer-events: auto;
}

.complianceCont:hover{
    background-image: url('https://wealthcdn.aions.co.za/compliance/carLeft.png') !important;
    // background-color: red;
}