.signUpStepper{
    height: 50px;
    margin-top: 20px;
    margin-bottom: 15px;
    width: 95%;
    // border: 1px solid black;
    display: flex;
    align-items: center;
}

.signUpStepperStep{
    background-color: #738391;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.signUpStepperDivider{
    width: 20%;
    margin-bottom: 7%;
}

.signUpStepperStepActive{
    background-color: #E2357E !important;
}

.signUpStepperStepComplete{
    background-color: #8DC972 !important;
}

.signUpStepperStepCaption{
    font-size: 12px;
    margin-left: 5px;
    margin-right: 5px;
}

.signUpStepperDesktop{
    height: 50px;
    margin-top: 20px;
    margin-bottom: 15px;
    width: 70%;
    // border: 1px solid black;
    display: flex;
    align-items: center;
}

.signUpStepperDividerDesktop{
    width: 30%;
}

.registerText{
    margin-top: 1px;
    margin-bottom: 1px;
}

.desktopSignupHeaderDiv{
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
}