.conversions {
    min-height: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    padding: 0 0.5% 0 0.5%;
    align-items: center;
    overflow: hidden;

    .heading {
        height: 10%;
        width: 100%;
        margin: 0px;
        font-size: 45px;
        font-weight: 900;
        line-height: 52.73px;
        text-align: left;
        padding-left: 1%;
    }

    .conversions__figures {
        height: 14vh;
        width: 98%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 1% 0 0.5% 0;
    }

    .conversions__container {
        height: 320px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 1%;

        .conversion_pie_block {
            height: 100%;
            width: 20%;
        }
    }
}