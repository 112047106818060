.AdminCompHeader{
    width: 90%;
    border-radius: 12px;
    background-color: #FFFFFF;

    p{
        color: #4f1b87;
    }

    .adminCompType{
        border: 1px solid #D1427E;
        border-radius: 12px;
        width: 45%;
        cursor: pointer;
    }
    .adminCompTypeSelected{
        background-color: #D1427E;
        p{
            color: white !important;
        }
        
    }
}

.adminCompLeaderboardDiv{
    background-color: #FFFFFF;
    border-radius: 12px;
    p{
        color: #4f1b87;
    }
    width: 80%;
    margin: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.adminSecondyCompTypeDiv{
    background-color: #FFFFFF;
    margin: 10px;
    border-radius: 12px;
    border: 1px solid #D1427E;
    p{
        color: #4f1b87;
    }
    
}

.adminSecondyCompTypeDivSelected{
    background-color: #D1427E;
    p{
        color: white !important;
    }
    
}