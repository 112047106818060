.comptransactions,
.comptransactions_inverse {
    height: 240px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0%;
    position: relative;
    border-radius: 15px;
    background: rgba(217, 217, 217, 0.25);

    .signup_p {
        font-size: 36px;
        font-weight: 900;
        line-height: 52.73px;
        text-align: left;
        margin: 1.5px -1.5px 1.5px 0;
        width: 98%;
        display: flex;
        justify-content: space-between;

        .close_x {
            background-image: url('./images/x.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 70% 50%;
            height: 100%;
            width: 3%;
            cursor: pointer;
        }
    }

    .graphs_div {
        height: 100%;
        width: 99%;
        border-radius: 15px;
        align-items: center;
        display: flex;
        flex-direction: column;
        position: relative;


        .chart_div {
            height: 90%;
            width: 100%;
            padding: 0.5% 0%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }

        .bar_div {
            background: rgba(11, 19, 47, 1);
            height: 65%;
            width: 100%;
            // padding: 0 0 1.5% 0;
            border-radius: 15px;
            margin-top: 0.5%;

            .bar_p {
                font-size: 30px;
                font-weight: 900;
                line-height: 35.16px;
                text-align: center;
                height: 4%;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                width: 95%;
            }

            .carousel-root,
            .carousel-root_inverse {
                height: 98%;
                border-radius: 15px;

                .carousel {
                    margin: 20px;
                    overflow: hidden;
                    height: 0%;
                    width: 0;

                    .thumbs-wrapper {}
                }

                .carousel-slider {
                    position: relative;
                    margin: 0;
                    overflow: hidden;
                    height: 100%;

                    .control-dots {
                        visibility: hidden;
                    }

                    .slider-wrapper {
                        overflow: hidden;
                        margin: auto;
                        width: 100%;
                        transition: height .15s ease-in;
                        // background: black;
                        height: 100%;

                        .slider {
                            display: flex;
                            // background: gray;
                            height: 100%;

                            .slide {
                                min-width: 100%;
                                margin: 0;
                                position: relative;
                                text-align: center;
                                // background-color: #2a6091;
                                display: flex;
                                align-items: center;

                                .svgline_div {
                                    min-height: 100% !important;
                                    width: 98%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin-top: 0%;
                                    // background: green;
                                    padding-bottom: 0%;
                                }

                                .svgchart_div {
                                    display: flex;
                                    justify-content: space-around;
                                    align-items: center;
                                    height: 100%;
                                    width: 100%;
                                }
                            }
                        }
                    }

                    .control-arrow {
                        top: 2.5%;
                        right: 0.5%;
                        color: #fff;
                        font-size: 11px;
                        bottom: 0;
                        height: 10%;
                        width: 1.5%;
                        position: absolute;
                        margin-top: 0;
                        padding: 6px;
                        background-repeat: no-repeat;
                        background-size: 65%;
                        background-position: 35% 5%;
                        background-image: url('./images/rightArrow.svg');


                        &:before {
                            margin: 0 5px;
                            display: inline-block;
                            border: unset;
                            border: unset;
                            content: '';
                        }

                        &:hover {
                            background-color: transparent;
                        }
                    }
                }
            }

            .carousel-root_inverse {
                .carousel-slider {
                    .slider-wrapper {
                        .slider {
                            .slide {
                                min-width: 100%;
                                margin: 0;
                                position: relative;
                                text-align: center;
                                display: flex;
                                flex-direction: row;
                                justify-content: space-around;
                            }
                        }
                    }

                    .control-arrow {
                        left: 0.5%;
                        background-image: url('./images/leftArrow.svg') !important;
                    }
                }
            }
        }

        .token_div {
            display: flex;
            width: 100%;
            height: 8%;
            align-items: flex-end;

            .p_token {
                font-size: 25px;
                font-weight: 400;
                line-height: 29.3px;
                text-align: left;
                width: 100%;
                background: rgba(217, 217, 217, 0.25);
                border-radius: 10px;
                padding-left: 10px;
                height: 84%;
                display: flex;
                align-items: center;
                margin: 0% 0% 0 0% !important;

                span {
                    font-size: 25px;
                    font-weight: 700;
                    line-height: 29.3px;
                    text-align: left;
                }
            }

            .img_div {
                width: 2.5%;
                height: 85%;
                background: rgba(255, 255, 255, 0.25);
                border-radius: 12px;
                display: flex;
                align-items: flex-end;
                background-image: url('./images/addEmail.svg');
                background-repeat: no-repeat;
                background-position: center;
                cursor: pointer;
            }
        }

        .popup_div {
            position: absolute;
            border-radius: 15px;
            background: rgba(255, 255, 255, 0.25);
            height: 55%;
            width: 24%;
            top: 42%;
            left: 69%;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            align-items: stretch;

            .popup_item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0.5%;
                flex-direction: row;
                padding: 0% 5% 0% 0%;
                cursor: pointer;

                .p_popup,
                .p_popupSelected {
                    color: #FFF;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;

                }

                .p_popupSelected {
                    font-weight: 900;
                }

                .span_popup,
                .span_popupSelected {
                    background-position: center;
                    background-repeat: no-repeat;
                    background-image: url('./images/notSelected.svg');
                    width: 12%;
                    height: 100%;
                    cursor: pointer;
                }

                .span_popupSelected {}
            }
        }
    }
}

.comptransactions_inverse {
    background: none;

    .graphs_div {
       // background: rgba(255, 255, 255, 0.25);
        display: flex;
        justify-content: center;
        .chart_div {
            height: 75%;
            width: 100%;
            padding: 0.5% 0%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }
    }

    .signup_p {
        font-size: 36px;
        font-weight: 900;
        line-height: 52.73px;
        text-align: left;
        margin: 1.5px -1.5px 1.5px 0;
        width: 98%;
        display: flex;
        justify-content: center;
        .close_x {
            width: 5%;
            display: flex;
            margin-left: 40%;
            visibility: hidden;
            position: fixed;
        }
    }
}