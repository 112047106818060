@import '../../var.css';



.TopBar{
    /* background-color: transparent !important; */
    width: 100%;
    height: 50px;
    // /* height: 80px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #FFFFFF;
    padding: 0px;
    padding-left: 5px;
    // padding-top: 5px;
    // padding-bottom: 5px; 
    margin-bottom: 0px; 
    background-color: #0B132F !important; 
    /* filter: drop-shadow(0px -10px 30px #141414); */
    /* box-shadow-bottom: 0px 1px 10px 1px #ffffff inset; */
    
}

#menuBars{
    margin-left: 10px;
    margin-right: 10px;
    font-size: 25px;
}

.TopBar p{
    font-size: 30px;
}

#SidebarDiv{
    /* height: 100%; */
    width: 100vw;
    position: fixed;
    background-color: #000000b5;
    z-index: 999;
    overflow-y: scroll;
    flex-direction: row-reverse;
    position: absolute;
    top: 0px;
}

#SidebarDiv.hide{
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.5s, opacity 0.5s linear;
}

#SidebarDiv.show{
    
    visibility: visible;
    opacity: 1;
    transition: opacity 1s linear;

    #sideBar{
        position: absolute;
        right: 0vw;
        transition: right 0.5s;
    }

    .sidebarSecondary{
        right: 16vw;
        position: absolute;
    }
}
#sideBar{
    width: 20vw;
    height: 100%;
    // background-color: #492187;
    background-color: #0B132F;
    position: absolute;
    right: -16vw;
    transition: right 0.5s;
    overflow-x: hidden;
}

#sideBar p{
    font-size: 17px;

}

.sidebarItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    /* border: 1px solid black */
}

.sidebarItemInner{
    width: 100%;
}

.sidebarItemActive *{
    // color: pink;
}

.menuP{
    font-size: 22px !important;
    cursor: pointer;
}

.menuItemCascadeDiv{
    display: flex;
    align-items: center;
    /* color: white; */
}

.expandArrow{
    color: white;
}

.expandedArrow{
    color: white;
    font-size: 40px;
}

.menuItemCascadeDiv p{
    margin-right: 25px;
    margin-left: 35px;
}

.menuItemCascadeDiv img{
    width: 15%;
}

.sidebarSecondary{
    background-color: #112C42 !important;
    width: 60vw !important;
    position: absolute;
    right: 20vw !important;
}

.secondaryMenuItem{
    /* border: 1px solid black; */
    margin-top: 10px;
    margin-bottom: 10px;
}

.sidebarP {
    margin-top: 1px;
    
}

.secondaryMenuItemActive{
    // background-color: #794DCE;
    background: linear-gradient(90deg, rgba(229,46,128,1) 3%, rgba(0,107,172,1) 79%);
}

.hamburger-react{
    margin-bottom: 20px;
}

.socialMediaSB{
    // position: absolute;
    margin-top: 1vh;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img{
        margin-top: 1vh;
        margin-bottom: 1vh;
        height: 45px;
    }
}

.sidebarLocationDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.learnOptionsDesktop{
    width: 100vw;
    background-color: #112C42 !important;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.secondaryMenuItem p{
    cursor: pointer;
}


.menu{
    transition: all 0.5s;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 2001;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .menuContainer{
        width: 85%;
        height: 85%;
        background: linear-gradient(28deg, #0B132F 15.43%, #006BAC 252.83%);
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        // justify-content: space-between;
        .menuContent{
            height: calc(100% - 50px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 20px;
        }

        .menuHeader{
            width: 100%;
            height: 50px;
            background: linear-gradient(90deg, rgba(0,107,172,1) 25%, rgba(229,46,128,1) 64%);
            border-top-right-radius: 15px;
            border-top-left-radius: 15px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 20px;
        }

        .menuItem{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding-left: 30px;
            margin-bottom: 20px;
            p{
                margin: 0px;
                color: #FFF;
                font-size: 25px;
                font-weight: 900;
                margin-left: 10px;
            }
        }
    }
    .loggedInDiv{
        width: 100%;
        align-self: flex-end;
        p{
            color: #FFF;
            text-align: center;
            font-size: 20px;
            font-weight: 900;
            margin: 0px;
        }
    }
}

.menuHidden{
    opacity: 0 !important;
    // height: 0px !important;
    visibility: hidden !important;
}

.TopBarMenuDesktop{
    width: 100vw;
    height: 75px;
    margin: 0px;
    background-color: #0B132F;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img{
        height: 95%;
        margin-right: 20px;
        margin-left: 20px;
        cursor: pointer;
    }
}

.desktopSidebar{
    transition: all 0.5s;
    width: 20vw;
    background-color: #0B132F;
    position: absolute;
    top: 75px;
    left: -20vw;
    z-index: 500;
    display: flex;
    flex-direction: row;
    .menuList{
        background-color: #0B132F;
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6%;
        // .menuListItem:first-child{
        //     margin-top: 20%;
        // }
        .menuListItem{
            transition: all 0.5s;
            color: #FFF;
            font-size: 25px;
            font-weight: 300;
            margin: 0px;
            margin-left: 30px;
            cursor: pointer;
            text-align: left;
            max-width: 80%;
            line-height: normal;
        }
        .menuListItemActive{
            transition: all 0.5s;
            color: #FFF;
            font-size: 30px;
            font-weight: 900;
        }
    }
    .menuDivider{
        background: linear-gradient(180deg, #2A6091 0.04%, #E43180 117.78%);
        width: 10%;
        height: 100%;
    }
    .desktopMenuCoin{
        transition: all 0.5s;
        // width: 67px;
        // height: 67px;
        width: 19%;
        aspect-ratio: 1;
        position: absolute;
        left: 80%;
        // top: 0px !important;
        border-radius: 50%;
        background-color: #0B132F;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 60%;
            height: 60%;
        }

    }
}
.desktopSidebarActive{
    transition: all 0.5s;
    width: 20vw;
    background-color: #0B132F;
    position: absolute;
    top: 75px;
    left: 0px;
    .desktopMenuCoin{
        transition: all 0.5s;
        // width: 67px;
        // height: 67px;
        width: 19%;
        aspect-ratio: 1;
        position: absolute;
        left: 80%;
        border-radius: 50%;
        background-color: #0B132F;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 60%;
            height: 60%;
        }

    }
}
.desktopMenuOverlay{
    transition: all 0.5s;
    width: 80%;
    position: absolute;
    top: 75px;
    left: 20vw;
    background-color: #0B132F80;
    visibility: hidden;
    z-index: 500;
    opacity: 0;
}
.desktopMenuOverlayActive{
    transition: all 0.5s;
    visibility: visible;
    opacity: 1;
}

.DesktopTopBarMenuItem{
    color: #FFF;
    font-size: 30px;
    font-weight: 300;
    margin: 0px;
    flex-shrink: 0;
    cursor: pointer;
}
.DesktopTopBarMenuItemActive{
    font-weight: 900;
}