.hifofContainer{
    width: 100vw;
    height: 100%;
    position: relative;
    // top: 50px;
    // left: 0px;
    background-color: #0B132F;
    justify-content: flex-start;
    padding: 0px;
    overflow: hidden;
    // z-index: 2;

    .hifofheader{
        width: 100%;
        height: 100%;
        margin-top: 2%;
        border-top-left-radius: 100px;
        background: linear-gradient(60deg, rgba(13,20,45,1) 37%, rgba(17,44,66,1) 85%);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 20px;
        overflow-y: scroll;
        overflow-x: hidden;
        p{
            margin: 0px;
            color: #FFF;
            text-align: center;
            font-size: 15px;
            font-weight: 300;
            margin-bottom: 20px;
        }
    }
    .purchaseBody{
        width: 100%;
        height: 83%;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        background: linear-gradient(60deg, rgba(13,20,45,1) 37%, rgba(17,44,66,1) 85%);
        padding: 20px;
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}