.listingTrajectory{
    transition: width 0.2s, height 0.2s, position 0.7s, background-color 0.7s;
    width: 90%;
    height: 130px;
    background-color: white;
    border-radius: 15px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    .trajectorySvg{
        height: 75%;
    }
    .trajectoryHeader{
        margin: 0px;
        color: #12153D;
        text-align: center;
        font-size: 20px;
        font-weight: 900;
        line-height: normal;
    }
}


.listingTrajectoryActive{
    width: 100vw;
    height: calc(100vh - 50px);
    position: fixed;
    top: 50px;
    left: 0px;
    background-color: #0B132F;
    justify-content: flex-start;
    padding: 0px !important;
    z-index: 2;
    .trajectorySelectedHeader{
        width: 100%;
        height: 20%;
        margin-top: 2%;
        border-top-left-radius: 100px;
        background: linear-gradient(79deg, #006BAC 19.17%, #E52E80 82.6%);
        margin-bottom: -3%;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 50%;
        }
    }
    .trajectorySelectedBody{
        width: 100%;
        height: 83%;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        background: linear-gradient(60deg, rgba(13,20,45,1) 37%, rgba(17,44,66,1) 85%);
        // padding: 10px;
        // display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        overflow-y: scroll;
        overflow-x: hidden;
        .trajectoryHeader{
            color: #FFF;
            font-size: 20px;
            font-weight: 900;
            margin: 0px;
            text-align: left;
            width: 100%;
            margin-bottom: 48px;
            margin-top: 10px;
            margin-left: 10px;
        }
        .trajectoryCard{
            width: 80%;
            height: auto;
            background-color: white;
            border-radius: 15px;
            position: relative;
            min-height: 56px;
            padding-top: 28px;
            margin-bottom: 45px;
            .trajectoryCardIcon{
                width: 56px;
                height: 56px;
                background-color: #0B132F;
                border: 0.5px solid white;
                border-radius: 50%;
                position: absolute;
                left: 40%;
                top: -28px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            p{
                color: #0B132F;
                text-align: center;
                font-size: 15px;
                font-weight: 300;
            }
        }
    }
}