.newListingHomeTopContainer{
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;

    .newListingHomeTopSide{
        width: 49%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin: 20px;

        .tagsContainer{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            .tagSelector{
                width: 90%;
                height: 60px;
                background-color: #FFFFFF40;
                border-radius: 15px;
                display: flex;
                // #tagSelect{
                //     width: 100%;
                //     height: 100%;
                //     border-radius: 15px;
                // }
                .tagHeader{
                    width: 100%;
                    height: 100%;
                    padding: 10px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    position: relative;
                    .tagDropdownHeader{
                        color: #FFF;
                        font-family: Roboto;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        margin: 0px;
                        text-align: left;
                    }
                    .tagDropdownSubtext{
                        color: #FFFFFF80;
                        font-family: Roboto;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        margin: 0px;
                    }
                    .tagDropdownOptionContainer{
                        width: 100%;
                        background-color: #FFFFFF40;
                        border-bottom-left-radius: 15px;
                        border-bottom-right-radius: 15px;
                        position: absolute;
                        top: 60px;
                        left: 0px;

                        .tagItem{
                            margin: 0px;
                            color: #FFFFFF80;
                            font-family: Roboto;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                            cursor: pointer;
                            margin-top: 5px;
                            margin-bottom: 5px;
                        }
                        .tagItemActive{
                            color: #FFFFFF !important
                        };
                    }

                }
            }

            .imgUploadContainer{
                min-width: 60px;
                height: 60px;
                label{
                    width: 100%;
                    height: 100%;
                    border-radius: 15px;
                    cursor: pointer;
                    background-color: #FFFFFF40;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .descriptionContainer{
            width: 100%;
            border-radius: 15px;
            background-color: #FFFFFF40;
            padding: 10px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            .descriptionHeader{
                margin: 0px;
                color: #FFF;
                font-family: Roboto;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                align-self: flex-start;
            }
            .descriptionArea{
                width: 100%;
                resize: none;
                flex-grow: 1;
                background-color: transparent;
                border: none;
                outline: none;
                color: #FFF;
                font-family: Roboto;
                font-size: 20px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                padding: 0px;
            }
            .descriptionArea::placeholder{
                color: #FFFFFF80;
                font-family: Roboto;
                font-size: 20px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }
        }

        .socialMediaContainer{
            width: 100%;
            background-color: #FFFFFF40;
            border-radius: 15px;
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            .smHeader{
                margin: 0px;
                color: #FFF;
                font-family: Roboto;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-align: left;
                align-self: flex-start;
            }
            .smLine{
                width: 95%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                align-self: flex-end;
                .textInputContainer{
                    width: 75%;
                }
                .smType{
                    margin: 0px;
                    color: #FFF;
                    text-align: right;
                    font-family: Roboto;
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }
            }
        }
    }
}

.foundersContainer{
    width: 88%;
    border-radius: 15px;
    background-color: #FFFFFF40;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;

    .foundersHeading{
        margin: 0px;
        color: #FFF;
        font-family: Roboto;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        align-self: flex-start;
    }
    .foundersInputContainer{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .imgUploadContainer{
            width: 60px;
            height: 60px;
            label{
                width: 100%;
                height: 100%;
                border-radius: 15px;
                cursor: pointer;
                background-color: #FFFFFF40;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .existingFounderContainer{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        p{
            margin: 0px;
            width: 30%;
            text-align: left;
        }
        img{
            width: 5%;
        }
    }
}

.challengeAnswerContainer{
    width: 88%;
    padding: 10px;
    background-color: #FFFFFF40;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;

    .challengeAnswerHeader{
        margin: 0px;
        color: #FFF;
        font-family: Roboto;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: left;
        align-self: flex-start;
        margin-bottom: 30px;

    }
    .challengeAnswerRow{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .imgUploadContainer{
            width: 60px;
            height: 60px;
            label{
                width: 100%;
                height: 100%;
                border-radius: 15px;
                cursor: pointer;
                background-color: #FFFFFF40;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .challengeAnswerTextContainer{
            width: 90%;
            height: 150px;
            background-color: #FFFFFF40;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            padding: 10px;
            .challengeAnswerSectionHeader{
                margin: 0px;
                color: #FFF;
                font-family: Roboto;
                font-size: 15px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                align-self: flex-start;

            }

            .challengeAnswerSectionText{
                width: 100%;
                resize: none;
                flex-grow: 1;
                background-color: transparent;
                border: none;
                outline: none;
                color: #FFF;
                font-family: Roboto;
                font-size: 20px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                padding: 0px;
            }
            .challengeAnswerSectionText::placeholder{
                color: #FFFFFF80;
                font-family: Roboto;
                font-size: 20px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }
        }
    }
}