.heroSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    padding-bottom: 0px;
    position: relative;
    .heroText{
        margin: 0px;
        color: #FFF;
        text-align: center;
        font-size: 15px;
        font-weight: 300;
        // margin-bottom: 30px;
    }
    .mobileCoins{
        width: 100vw;
        position: absolute;
        top: 8%;
        // left: 3%;
    }
}

.hearItFromOurFounderSection{
    width: 100vw;
    padding: 20px;
    padding-bottom: 40px;
    background-color: #0B132F;
    margin-bottom: 25px;

    .knowMoreContainer{
        display: flex;
        flex-direction: row;
        img{
            width: 50%;
        }

        .knowMore{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 50%;
            padding: 10px;
            padding-left: 20px;
            p{
                margin: 0px;
                color: #FFF;
                text-align: center;
                font-size: 15px;
                font-weight: 300;
                margin-bottom: 20px;
            }
        }
    }

}

.desktopPlaceholder {
    width: 100vw;
    background: linear-gradient(28deg, #0B132F 15.43%, #006BAC 252.83%);
    position: relative;
    .dekstopTopbar{
        width: 100%;
        height: 70px;
        background-color: #0B132F;
    }

    .dskText{
        margin: 0px;
        color: #FFF;
        text-align: center;
        font-size: 25px;
        font-weight: 300;
    }

    .coins{
        position: absolute;
        width: 100%;
        // left: 20px;
        top: 50px;
    }
    .tree{
        width: 100%;
        position: absolute;
        bottom: 0px;
        left: 0px;
    }

    .qrcode{
        width: 16%;
        position: absolute;
        right: 15%;
        top: 35%;
    }

    .fspText{
        height: 50px;
        width: 100%;
        background-color: #0B132F;
        padding-left: 50px;
        padding-right: 50px;
        position: absolute;
        bottom: 0px;
        left: 0px;
        p{
            margin: 0px;
            color: #FFF;
            text-align: center;
            font-size: 13px;
            font-weight: 300;
            margin-top: 5px;
        }
    }
}