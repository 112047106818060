.ListingAdminHeader{
    color: #FFF;
    font-family: Roboto;
    font-size: 45px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin: 0px;
    margin-top: 30px;
}

.AEXListingAdminTableContainer{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .AEXListingAdminTableHeaderRow{
        width: 95%;
        display: flex;
        flex-direction: row;
        align-items: center;

        p{
            color: #FFF;
            font-family: Roboto;
            font-size: 30px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            margin: 0px;
        }
    }
    .listingAdminTableHeaderDivider{
        width: 100%;
        height: 3px;
        background-color: #FFFFFF40;
        border-radius: 15px;
        margin-bottom: 12px;
    }

    .lisitngTableRow{
        width: 99%;
        height: 60px;
        padding-left: 2%;
        padding-right: 2%;
        background-color: #D9D9D940;
        margin-bottom: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        p{
            margin: 0px;
            color: #FFF;
            font-family: Roboto;
            font-size: 25px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}