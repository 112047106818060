.listingInvest{
    transition: width 0.2s, height 0.2s, position 0.7s, background-color 0.7s;
    height: 50%;
    background-color: #E43180;
    width: 100vw;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    .listingInvestText{
        width: 100%;
        height: 15%;
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: red;
        p{
            margin: 0px;
            color: #FFF;
            text-align: center;
            font-size: 25px;
            font-weight: 900;
            width: 100%;
        }
    }
}

.listingInvestActive{
    width: 100vw;
    height: calc(100vh - 50px);
    position: fixed;
    top: 50px;
    left: 0px;
    background-color: #0B132F;
    justify-content: flex-start;
    padding: 0px;
    z-index: 2;

    .investSelectedHeader{
        width: 100%;
        height: 20%;
        margin-top: 2%;
        border-top-left-radius: 100px;
        background: linear-gradient(79deg, #006BAC 19.17%, #E52E80 82.6%);
        margin-bottom: -3%;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 50%;
        }
    }
    .investSelectedBody{
        width: 100%;
        height: 83%;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        background: linear-gradient(60deg, rgba(13,20,45,1) 37%, rgba(17,44,66,1) 85%);
        padding: 20px;
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        overflow-y: scroll;
        overflow-x: hidden;

        .investmentSummary{
            width: 90%;
            border-radius: 15px;
            background-color: #D9D9D9;
            padding: 10px;
            padding-top: 16px;
            padding-bottom: 16px;
            margin-bottom: 30px;
            .withdrawalDate{
                margin: 0px;
                color: #0B132F;
                text-align: center;
                font-size: 13px;
                font-weight: 300;
                margin-bottom: 16px;
            }
            .investSummaryPredictions{
                width: 100%;
                display: flex;
                flex-direction: row;
                .investSummaryPred{
                    width: 50%;

                    .sumHeading{
                        margin: 0px;
                        color: #0B132F;
                        text-align: center;
                        font-size: 13px;
                        font-weight: 300;
                    }
                    .sumAmount{
                        margin: 0px;
                        color: #0B132F;
                        text-align: center;
                        font-size: 22px;
                        font-weight: 700;
                    }
                    .sumPercent{
                        margin: 0px;
                        text-align: center;
                        font-size: 13px;
                        font-weight: 700;
                    }
                }
            }
        }

        .investmentAmountContainer{
            width: 90%;
            height: 60px;
            border-radius: 15px;
            background-color: #FFFFFF40;
            display: flex;
            align-items: center;
            justify-content: center;
            p{
                margin: 0px;
                color: #FFF;
                text-align: center;
                font-size: 30px;
                font-weight: 700;
            }
        }

        .keyboard{
            margin-top: 20px;
            margin-bottom: 20px;
            transition: transform 0.5s, opacity 0.5s, height 0.5s, visibility 0.5s;
            transform: translateY(0px);
            width: 100%;

            .keyboardRow{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                margin-bottom: 20px;
                .keyboardItem{
                    width: 20vw;
                    height: 20vw;
                    border-radius: 50%;
                    background-color: #475C77;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    p{
                        margin: 0px;
                        color: #FFF;
                        text-align: center;
                        font-size: 30px;
                        font-weight: 700;
                    }
                }
            }
        }
        .keyboardHidden{
            transform: translateY(-80px)!important;
            opacity: 0 !important;
            height: 0px !important;
            visibility: hidden !important;
        }
        .keyboard.visible {
            transform: translateY(0); 
            opacity: 1;
        }
    }
    .investSelectedBody > * {
        flex-shrink: 0;
      }
}
