.journeyNavigator{
    width: 100%;
    // height: 50vw;
    display: flex;
    flex-direction: row;
    padding: 20px;
    padding-bottom: 0px;
    // justify-content: space-between;
    // justify-content: center;
    overflow-x: scroll;
    white-space: nowrap; 
    overflow-y: auto;

    .journeyItem{
        width: 38vw;
        margin-left: 10px; 
        margin-right: 10px;
        height: calc(35vw + 55px);
        position: relative;
        img{
            width: 35vw;
            height: 35vw;
        }
        .journeyDescription{
            position: absolute;
            bottom: 25px;
            width: 75%;
            height: 55px;
            border-radius: 0px 20px 20px 20px;
            background-color: #FFF;
            display: flex;
            align-items: center;
            justify-content: center;
            p{
                color: #0B132F;
                font-size: 17px;
                font-weight: 900;
                width: 100%;
                margin: 0px;
                overflow: hidden;
                white-space: normal;
                word-wrap: break-word;
            }
        }
    }
}