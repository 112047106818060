.eventCodePopupContainer{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #0B132F80;
    z-index: 5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .eventCodePopup{
        width: 90%;
        height: 90%;
        background: linear-gradient(15deg, #0B132F 5.33%, #006BAC 245.1%);
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        .eventCodeHeader{
            width: 100%;
            height: 60px;
            background: linear-gradient(90deg, #2A6091 0.04%, #E43180 117.78%);
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            img{
                height: 90%;
                margin-left: 10px;
            }
        }
        .eventCodeText{
            margin: 0px;
            margin-top: 15px;
            margin-bottom: 15px;
            color: #FFF;
            font-family: Roboto;
            font-size: 30px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
        }

        .eventCodeSuccessText{
            margin: 0px;
            margin-top: 15px;
            margin-bottom: 15px;
            color: #FFF;
            text-align: center;
            font-family: Roboto;
            font-size: 35px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
        }
        .eventCodeSubText{
            color: #FFF;
            text-align: center;
            font-family: Roboto;
            font-size: 15px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }

        .eventCodeError{
            margin: 0px;
            margin-top: 15px;
            margin-bottom: 15px;
            color: #D42E79;
            text-align: center;
            font-family: Roboto;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }
}