.updateMenuOptions{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .headerContainer{
        display: flex; 
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 10px;
        .activePageHeader{
            margin: 0px;
            color: #FFF;
            text-align: center;
            font-family: Roboto;
            font-size: 65px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
        }
    }
    .menuContainer{
        width: 90%;
        height: 30%;
        border-radius: 15px;
        background-color: #D9D9D940;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        .menuItem{
            width: 20%;
            height: 85%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 15px;
            cursor: pointer;
            p{
                margin: 0px;
                color: #FFF;
                text-align: center;
                font-family: Roboto;
                font-size: 30px;
                font-style: normal;
                font-weight: 900;
                line-height: normal;
            }
        }
        .menuItemActive{
            background-color: #FFFFFF40;
        }
    }
}

.updateContent{
    width: 100%;
}