.allDependantsContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    .headerNoDependants{
        color: #0B132F;
        text-align: center;
        font-family: Roboto;
        font-size: 13px;
        font-style: normal;
        font-weight: 300;
    }
    .headerDependants{
        color: #0B132F;
        font-family: Roboto;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        text-align: left;
        width: 100%;
    }

    .depListItemName{
        color: #E43180;
        font-family: Roboto;
        font-size: 15px;
        font-style: normal;
        font-weight: 300;
        text-align: left;
        text-decoration-line: underline;
        width: 100%;
        margin-top: 0px;

    }
}