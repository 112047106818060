.dependantDetailContainer{
    display: flex;
    flex-direction: column;
    align-items: center;

    .headerText{
        color: #0B132F;
        font-family: Roboto;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        text-align: left;
        width: 100%;
    }

    .divider{
        width: 100%;
        height: 0.5px;
        background-color: #00000040;
    }
    .walletFundsContainer{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        .walletText{
            color: #0B132F;
            text-align: right;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }

    .transactionTable{
        width: 100%;
        .tableHeader{
            width: 100%;
            display: flex;
            flex-direction: row;
            .tableHeaderItem{
                color: #0B132F;
                font-family: Roboto;
                font-size: 13px;
                font-style: normal;
                font-weight: 700;
                margin: 0px;
                margin-top: 10px;
                margin-bottom: 5px;
            }
        }
        .headerDivider{
            width: 100%;
            height: 2px;
            background-color: #00000040;
        }
    }
}