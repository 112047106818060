.rolesHeaderContainer{
    width: 95%;
    height: 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1%;
    p{
        color: #FFF;
        font-family: Roboto;
        font-size: 45px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        margin: 0px;
    }

    .newUserRoleButton{
        height: 100%;
        aspect-ratio: 1/1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        background-color: #FFFFFF40;
        cursor: pointer;
    }

}

.rolesUserTable{
    width: 95%;
    height: 80%;
    border-radius: 15px;
    background-color: #FFFFFF40;
    padding: 10px;
    overflow: scroll;
    .rolesUserTableRow{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .nameP{
            width: 50%;
            color: #FFF;
            font-family: Roboto;
            font-size: 25px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            margin: 0px;
            text-align: left;
        }

        .userRoleContainer{
            background-color: #FFFFFF40;
            padding: 5px;
            border-radius: 15px;
            margin-right: 10px;
            p{
                color: #FFF;
                text-align: center;
                font-family: Roboto;
                font-size: 25px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                margin: 0px;
            }
        }
    }
    .rolesUserTableRowDivider{
        width: 100%;
        height: 1px;
        background-color: #FFFFFF40;
        border-radius: 15px;
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

.newRolePopupContainer{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: #00000080;
    display: flex;
    align-items: center;
    justify-content: center;
    .newRolePopup{
        width: 80%;
        height: 80%;
        border-radius: 15px;
        background: linear-gradient(15deg, #0B132F 5.33%, #006BAC 245.1%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: 'flex-start';

        .newRolePopupHeader{
            width: 100%;
            height: 50px;
            border-radius: 15px 15px 0px 0px;
            background: linear-gradient(90deg, #2A6091 0.04%, #E43180 117.78%);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            img{
                height: 100%;
                margin-left: 5px;
            }
        }
    }

    .rolePermissionContainer{
        width: 95%;
        display: flex;
        flex-direction: row;
        background-color: #FFFFFF40;
        border-radius: 15px;
        margin-top: 10px;

        .rolePermissionSide{
            width: calc(50% - 1px);

            .rolePermissionOptionContainer{
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 10px;

                p{
                    color: #FFF;
                    text-align: right;
                    font-family: Roboto;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    margin: 0px;
                    cursor: pointer;
                }
                img{
                    cursor: pointer;
                }
            }
        }
        .rolePermissionSideDivider{
            width: 1px;
            height: 99%;
            background-color: #FFFFFF40;
        }
    }
}