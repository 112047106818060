/* Base styles for the slidingPasswordInput */
.slidingPasswordInput {
    // transition: transform 1s; /* Apply a transition for smooth animation */
    transition: transform 0.5s, opacity 0.5s, height 0.5s, visibility 0.5s;
    transform: translateY(0px); /* Initially hidden below */
  
    /* Add any other styles you want for the hidden input here */
  }
  
  /* Additional styles for the slidingPasswordInput when webauthn is false */
  .slidingPasswordInputHidden {
    // display: none !important; /* Hide the hidden input */
    transform: translateY(-80px)!important; /* Hidden position */
    opacity: 0 !important;
    height: 0px !important;
    visibility: hidden !important;
  }
  
  /* Additional styles for the slidingPasswordInput when webauthn is true */
  .slidingPasswordInput.visible {
    transform: translateY(0); /* Visible position */
    // display: block !important; /* Show the input */
    opacity: 1;
  }