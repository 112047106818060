.comp_bar {
  height: 100%;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .name {
    margin: 0px;
    font-size: 30px;
    font-weight: 900;
    line-height: 35.16px;
    text-align: center;
  }

  .transcomppop {
    position: absolute;
    border-radius: 15px 15px 15px 0px;
    display: block;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 10%;
    cursor: pointer;
    color: white;
    background: transparent;
  }
}