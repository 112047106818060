.checkbox{
  display: flex;
  width: 100%;
  margin-left: 20%;
  align-items: center;
  padding: 1px;

  p{
      margin-left: 0px;
      text-align: left;
      width: 60%;
  }
}

.circleCheckBox{
  position: relative;
  // background-color: grey;
  border: 1px solid white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.circleCheckBoxTicked{
  background-color: #E2357E;
}

.checkboxCheckmark{
  position: absolute;
  transform: rotate(45deg) translate(-50%, -50%);
  left: 34%;
  top: 50%;
  height: 13.3333333333px;
  width: 6.6666666667px;
  border-bottom: 3px solid white;
  border-right: 3px solid white;
}