.adminExternalCompContainer{
    width: 100%;
    height: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    .externalResults{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        margin-top: 5px;
        p{
            width: 20%;
        }
    }
}