@import '../../var.css';

#Sidebar{
    position: fixed;
    background-color: #E6D2EE;
    width: 80vw;
    /* transform: translateX(-220%);
    /* transform: translateX(0%);
    transition: transform ease-out 0.3s; */
    left: 0;
    transition: transform .3s
                cubic-bezier(0, .52, 0, 1);
    overflow: scroll;
    z-index: 1000;

}

/* #Sidebar.is-open{
    transform: translateX(220);
} */

#Sidebar.hide{
    transform: translate3d(-60vw, 0, 0);
}

#Sidebar.show{
    transform: translate3d(0vw, 0, 0);
    overflow: hidden;
}