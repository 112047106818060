.adminUsersContainer{
    width: 100%;
    height: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;

    .adminUsersLine{
        width: 90%;
        border: 1px solid #ffffff;
        border-radius: 15px;
        margin-top: 5px;
        margin-bottom: 5px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 5px;
    }

    .adminUsersDetailContainer{
        width: 78%;
        overflow: scroll;
        background: linear-gradient(60deg, rgba(13,20,45,1) 37%, rgba(17,44,66,1) 85%);
        position: absolute;
        top: 100px;
        display: flex;
        flex-direction: row;
        border: 1px solid #E52E80;
        border-radius: 15px;
    }
}