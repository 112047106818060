.newUpdateContainer{
    width: 50%;
    max-width: 50%;
    height: 100%;
    padding: 1%;
    .inputsContainer{
        width: 100%;
        max-width: 100%;
        height: 100%;
        border-radius: 15px;
        background-color: #FFFFFF40;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        .newUpdateHeading{
            width: 95%;
            max-width: 95%;
            height: calc(10% - 3px);
            margin: 0px;
            margin-top: 10px;
            border: none;
            outline: none;
            text-align: left;
            color: #FFF;
            font-family: Roboto;
            font-size: 25px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
        }
        .newUpdateInputDivider{
            width: 96%;
            height: 3px;
            background-color: #FFFFFF40;
            border-radius: 15px;
            margin-top: 5px;
        }

        .content-wrapper{
            width: 95%;
            max-width: 95%;
            height: calc(85% - 6px);
            margin-top: 3px;
            .rdw-editor-toolbar{
                background-color: #FFFFFF40;
                border: none;
                border-radius: 10px;
                .rdw-option-wrapper {
                    background-color: #FFFFFF40;
                    border: none;
                }
                .rdw-option-active{
                    background-color: #D42E79;
                }
            }
        }
        .content-editor{
            color: #FFF;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            overflow: scroll;
            height: 95%;
        }

        .newUpdateContent{
            width: 95%;
            margin-top: 10px;
            height: calc(85% - 3px);
            outline: none;
            border: none;
            background-color: transparent;
            resize: none;
            overflow: scroll;
            color: #FFF;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }
    }
}

.newUpdateContainerLeft{
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    .contentSideLeft{
        height: 100%;
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        .schedulerContainer{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        .previewHeader{
            color: #FFF;
            text-align: center;
            font-family: Roboto;
            font-size: 30px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
        }

        .previewContainer{
            width: 70%;
            aspect-ratio: 1.3/1;
            border-radius: 0px 15px 15px 15px;
            background-color: #FFF;
            box-shadow: 0px 6px 15px 2px rgba(0, 0, 0, 0.10);

            .imgPlaceholder{
                width: 100%;
                height: 60%;
                border-radius: 0px 15px 15px 15px;
                .imagePreviewCarousel{
                    margin: 0px;
                    width: 100%;
                    height: 100%;
                    border-radius: 0px 15px 15px 15px;
                    .imagePreviewContainer{
                        margin: 0px;
                        height: 100%;
                        img{
                            width: 100%;
                            height: 100%;
                            max-height: 100%;
                            border-radius: 0px 15px 15px 15px;
                        }
                        p{
                            margin: 0px;
                        }
                    }
                }
                .carousel{
                    height: 100%;
                    border-radius: 0px 15px 15px 15px;
                    .slider-wrapper{
                        height: 100%;
                        border-radius: 0px 15px 15px 15px;
                        ul{
                            height: 100%;
                            border-radius: 0px 15px 15px 15px;
                        }
                    }
                }
            }
            .previewContent{
                width: 100%;
                max-width: 100%;
                height: 40%;
                max-height: 40%;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                position: relative;
                p{
                    color: #0B132F;
                    font-family: Roboto;
                    font-size: 15px;
                    font-style: normal;
                    // font-weight: 300;
                    line-height: normal;
                    max-width: 95%;
                    margin: 0px;
                    margin-top: 5%;
                    max-height: 80%;
                    overflow: hidden;
                    text-align: left;
                    display: -webkit-box;
                    -webkit-box-orient: vertical; 
                    -webkit-line-clamp: 3;
                }
                .readMoreText{
                    position: absolute !important;
                    bottom: 10%;
                    right: 5%;
                    color: #E43180;
                    font-family: Roboto;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: normal;
                }
            }
        }
    }
    .contentSideRight{
        height: 100%;
        width: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        .imageUpload{
            width: 80%;
            height: 17%;
            background-color: #FFFFFF40;
            border-radius: 15px;
            position: relative;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}