.pie_corn {
  height: 315px;
  width: 100%;
  position: relative;

  .white,
  .pink {
    position: absolute;
    top: 82%;
    left: 55%;
    width: 15%;
    color: #fafafa;
    font-weight: 500;
    font-size: 15px;
    padding: 0%;
  }

  .pink {
    left: 5%;
    //top: 80%;
    color: #D42E79;
    width: 30%;
  }
}

.arc text {
  position: relative;
  z-index: 2;
}