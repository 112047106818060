.adminTopBar{
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    width: 20%;
    border: 1px solid white;
    margin-top: 30px;
}

.adminTopBarItem{
    cursor: pointer;
    p {
        font-size: 25px;
        text-align: left;
    }
}

.adminUserInfoDiv{
    border-bottom: 1px solid white;
    width: 100vw;
    margin-bottom: 30px;
}

.adminUserCategory{
    width: 40vw;
    border: 1px solid white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.adminUserCatDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.adminUserCard{
    width: 90%;
}

.userInfoDiv{
    width: 80vw;
    height: 60vw;
    border: 1px solid white;
    position: absolute;
    top: 5vw;
    z-index: 1;
    background-color: #35125A;
    border-radius: 20px;;

}

.adminLandingPageDiv{
    // margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 90vw;
    overflow-y: scroll;

    .lpSectDiv{
        display: flex;
        flex-direction: column;
        align-items: left;
        width: 100%;
        padding: 10px;
        
    }

    textArea{
        width: 80%;
        resize: vertical;
        margin-bottom: 20px;
    }
    
}
#lpPink{
    background-color: #E2357E;
}
#lpLilac{
    background-color: #908BC3;
}
#lpgrey{
    background-color: #404952;
}
#lpblue{
    background-color: #33C0D1;
}
#lpgreen{
    background-color: #8DC972;
}

.comDetailNav{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 1px solid white;
    margin-top: 30px;
    height: 10%;
    margin-top: 2px;
    margin-bottom: 2px;
}

.comDetailNavItem{
    cursor: pointer;
    p {
        font-size: 25px;
    }
}

.adminCompaniesCard{
    border: 1px solid white;
    background-color: transparent;
    cursor: pointer;
}

.companyInfoAdminDiv{
    border: 1px solid white;
    border-radius: 25px;
    width: 80vw;
    height: 80vh;
    position: absolute;
    background-color: #35125A;
    top: 10vh;
    left: 10vw;
    padding: 10px;
}

.closeCompanyInfo{
    color: white;
    font-size: 25px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.compDetails{
    height: 80%;
    overflow: scroll;
}

.theProblemCard{
    width: 98%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 25px;
    .deleteTheProblem{
        color: white;
        font-size: 25px;
        cursor: pointer;
    }
}

.newProdServCard{
    background-color: transparent;
    border: 1px solid white;
    border-radius: 25px;
    width: 98%;
}

.psExistingCard{
    width: 98%;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 25px;
    position: relative;
    .deletePS{
        color: white;
        font-size: 25px;
        cursor: pointer;
        position: absolute;
        top: 5px;
        right: 5px;
    }
}