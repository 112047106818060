.styled-table {
  width: 98%;
  border-collapse: collapse;
  height: 100%;
  font-size: 18px;
  text-align: left;
  color: white;
  overflow-y: scroll;
  background: rgba(217, 217, 217, 0.2509803922);
  border-radius: 15px;
  margin: 0.5% 1%;
}

.styled-table thead tr {
  color: white;
  text-align: left;
  font-weight: bold;
}

.styled-table th,
.styled-table td {
  padding: 2% 4% 0% 4%;
  width: 35%;
}

.styled-table tbody tr {
  // border-bottom: 1px solid #dddddd;
  height: 0vh;
}

.hr {
  background-color: #dddddd;
  height: 1px;
  width: 100%;
}

.styled-table tbody tr:nth-of-type(even) {}

.styled-table tbody tr:last-of-type {
  // border-bottom: 2px solid #343A40;
}