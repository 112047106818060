.accountManagerHeader{
    width: 100%;
    height: 18%;

    .accountManagerHeaderSubtitle{
        color: #FFF;
        font-size: 30px;
        font-weight: 300;
        line-height: normal;
        margin: 0px;
        text-align: left;
        margin-top: 1%;
        margin-left: 2%;
    }
    .accountManagerHeaderHeading{
        color: #FFF;
        font-size: 45px;
        font-weight: 900;
        line-height: normal;
        margin: 0px;
        text-align: left;
        margin-left: 2%;
    }
    .accountManagerHeaderDivider{
        width: 96%;
        height: 3px;
        flex-shrink: 0;
        background-color: #FFFFFF40;
        margin-left: 2%;
        margin-top: 1%;
    }
}

.accountManagerContent{
    width: 100%;
    height: 82%;
    overflow-y: scroll;
    .accountManagerHeaderDivider{
        width: 96%;
        height: 3px;
        flex-shrink: 0;
        background-color: #FFFFFF40;
        margin-left: 2%;
        margin-top: 1%;
    }
    .accountManagerContentHeader{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 1%;
        Button{
            margin: 0px;
        }
        p{
            margin: 0px;
            margin-left: 2%;
            color: #FFF;
            font-size: 45px;
            font-weight: 900;
            line-height: normal;
        }
    }
    .accountsTableHeaders{
        color: #FFF;
        font-size: 30px;
        font-weight: 900;
        line-height: normal;
        width: 19%;
        text-align: left;
        margin: 0px;
        margin-left: 1%;
    }
    .accountItem{
        width: 96%;
        height: 65px;
        background-color: #D9D9D940;
        margin-left: 2%;
        margin-top: 10px;
        display: flex;
        align-items: center;
        position: relative;
        .accountsTableContents{
            width: 19%;
            text-align: left;
            margin: 0px;
            margin-left: 1%;
        }
    }
    
}

.NewAcountPopupContainer{
    width: 100%;
    height: 100%;
    background-color: #0B132F80;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .popupHeader{
        width: 100%;
        height: 64px;
        border-radius: 15px 15px 0px 0px;
        background: linear-gradient(90deg, #2A6091 0.04%, #E43180 117.78%);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        img{
            height: 90%;
            margin-left: 15px;
        }
    }
    .NewAcountPopup{
        width: 80%;
        min-height: 60%;
        max-height: 80%;
        overflow-y: scroll;
        border-radius: 15px;
        background: linear-gradient(15deg, #0B132F 5.33%, #006BAC 245.1%);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .newAccountHeader{
            color: #FFF;
            font-size: 30px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            text-align: left;
        }
        .newAccountError{
            margin: 0px;
            color: #D42E79;
            text-align: center;
            font-size: 15px;
            font-weight: 700;
            line-height: normal;
        }

        .accountCreationResultText{
            color: #FFF;
            text-align: center;
            font-size: 40px;
            font-weight: 900;
            line-height: normal;
        }
        
    }
}

