.carouselContentDiv{
    background-color: transparent !important;
}

.carouselContentDiv p{
    /* margin-top: 0px; */
}

.welcomeDiv{
    width: 100%;
    margin-top: 20px !important;
    
}

.companyCarouselDiv{
    border: 1px solid #ffffff;
    border-radius: 5px;
    height: 520px;
    margin-right: 2px;
    margin-left: 2px;
}

.companyCarouselDiv p{
    text-align: left;
}

.carousel-root{
    bottom: 0px;
    position: absolute;
}



.welcomDesktopDiv{
    display: flex;
    flex-direction: row;
    height: 80%;
}

.welcomeDesktopSubDiv{
    width: 50vw;
}

.welcomeDesktopSubDiv p{
    text-align: left;
    margin-top: 15px;
    margin-bottom: 15px;
}

.welcomCarouselContent{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.welcomCarouselHeading{
    font-size: 50px;
    font-weight: bold;
    text-align: center;
}

.welcomCarouselSubheading{
    font-size: 40px;
    text-align: center;
    margin-bottom: 10px;
}

.welcomCarouselCont{
    font-size: 30px;
    font-weight: lighter;
    text-align: center;
    margin-top: 10px;
}

.welcomCarouselContent div{
    padding-left: 50px;
    padding-right: 50px;
}

.desktopHeaderSection{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.desktopHeaderSection p{
    font-size: 60px;
    font-weight: bold;
}

.desktopHeaderSection img{
    width: 50%;
}

.compWelcomeDiv{
    /* background-color: #382966; */
    /* background: linear-gradient(60deg, rgba(13,20,45,1) 37%, rgba(17,44,66,1) 85%); */
    width: 100%;
    /* padding-left: 10%; */
    /* padding-top: 30px; */

}

.welcomeCompSubtitle{
    font-weight: lighter;
    text-align: left;
    margin: 0px;
    font-size: 20px;
    /* line-height: 35px; */
}

.welcomeCompTitle{
    font-weight: bolder;
    text-align: left;
    margin: 0px;
    font-size: 45px;
    /* line-height: 30px; */
}

.welcomeCompP{
    text-align: left;
    margin-left: 5px;
    margin-bottom: 10px;
}

.welcomeCompStepIcon{
    color: white;
    background-color: #D1427E;
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    min-width: 30px;
    min-height: 30px;
}

.welcomeCompStepDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.welcomeCompButton{
    width: 80%;
    height: 45px;
    color: white;
    background-color: #D1427E;
    outline: none;
    border: none;
    border-radius: 12px;
    font-weight: bolder;
    font-size: 22px;
}

.compVideoDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-top: 20px; */
    /* margin-bottom: 50px; */
}

.welcomeCompVideo{
    width: 100vw;
    height: 100vw;
    background-color: #000000;
    /* box-shadow: 25vw 30px 0px 0px #0B132F; */
    display: flex;
}

.compCompanyImageCar{
    /* background-color: #0B132F; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 100px; */
    width: 100%;
    /* margin-bottom: 30px; */
}

.compCompanyImageCar img{
    /* width: 80% !important; */
}

.desktopWelcomeCompDiv{
    /* background-color: #382966; */
    width: 100%;
    /* margin-top: 40px; */
    padding-top: 30px;
}

.welcomeDesktopCompFlex{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.welcomeCompVideoDesktop{
    width: 40vw;
    height: 40vw;
    background-color: #000000;
    border-radius: 12px;
    /* box-shadow: 25vw 30px 0px 0px #0B132F; */
    display: flex;
}

.compVideoDivDesktop{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 50px;
    padding-right: 10vw;
    margin-left: 5vw;
}
.welcomeCompButtonDesktop{
    width: 25%;
    height: 60px;
    color: white;
    background-color: #D1427E;
    outline: none;
    border: none;
    border-radius: 12px;
    font-weight: bolder;
    position: relative;
    bottom: 50px;
    font-size: 25px;
}
.compInvestWelcomeDesktop{
    width: 100%;
    display: flex;
    justify-content: center;
}

.compCompanyImageCarDesktop{
    /* background-color: #312156; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 100px; */
    margin-bottom: 30px;
    /* padding-top: 5px; */
    padding-bottom: 5px;
    width: '100%';
}

.compCompanyImageCarDesktop img{
    /* width: 80% !important; */
    /* height: 100%; */
    /* object-fit: contain */
}

.welcomeCompTsCs{
    text-align: left;
    font-size: 10px;
}

.welcomeCarousel{
    margin-top: 0px !important;
}

.welcomeInvest{
    background-color: #0B132F;
    height: 80px;
    width: 100%;
    position: absolute;
    bottom: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.welcomeInvestBut{
    margin: 0px !important;
}

.desktopCaro{
    margin-top: 0px  !important;
}

.arrowImage{
    width: 40%;
    /* margin-left: 10%; */
    margin-bottom: 20px;
    margin-right: 30%;
}

/* .welcomeUnderline{
    border-bottom: 5px solid;
    border-image: linear-gradient(to right, #006bac, #e52e80) 1;
} */

.welcomeUnderline{
    position: relative;
    z-index: 5;
}
.welcomeUnderline::after{
    /* background: #000; */
    /* background: linear-gradient(to right, #006bac, #e52e80) 1;   */
    background: rgb(0,107,172);
    background: linear-gradient(90deg, rgba(0,107,172,1) 25%, rgba(229,46,128,1) 64%);
    content: "";
    position: absolute;
    bottom: 5px;
    left: -4%;
    height: 7px;
    width: 110%;
    z-index: -1; 
}

.welcomeUnderlineDesktop{
    position: relative;
    z-index: 5;
}
.welcomeUnderlineDesktop::after{
    /* background: #000; */
    /* background: linear-gradient(to right, #006bac, #e52e80) 1;   */
    background: rgb(0,107,172);
    background: linear-gradient(90deg, rgba(0,107,172,1) 25%, rgba(229,46,128,1) 64%);
    content: "";
    position: absolute;
    bottom: 10px;
    left: 0;
    height: 10px;
    width: 105%;
    z-index: -1; 
}