@import './var.css';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.view{
  /* background: #35125A; */

  background: linear-gradient(60deg, rgba(13,20,45,1) 37%, rgba(17,44,66,1) 85%);

  /* background-image: url(./assets/images/AEXLogo.svg);
  background-repeat: no-repeat;
  background-size: cover; */
}

html{
  background: #0d142d;
}

html *{
  font-family: Roboto !important;
}

.errorMessage{
  color: red;
  text-align: center;
}


.lds-circle {
  display: inline-block;
  transform: translateZ(1px);
}
.lds-circle > div {
  display: inline-block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  background: #cccccc;
  animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
@keyframes lds-circle {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}

.loadingDiv{
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff63;
}


.heading{
  font-size: 40px;
  color: white;
  font-weight: bold;
}

.headingDesktop{
  font-size: 50px;
  color: white;
  font-weight: bold;
  margin-top: 0px;
}

.subheading{
  font-size: 20px;
  color: white;
}

.subheadingDesktop{
  font-size: 30px;
  color: white;
}

p{
  color: white;
  font-size: 18px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
}

body{
  overflow: hidden;
}

label{
  color: #ffffff;
}

.errorMessage{
  color: red;
}

.successMessage{
  color: green;
}

.backArrow{
  color: white;
  margin-top: 20px;
  font-size: 20px;
}

.centerContent{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentDivider{
  width: 40%;
  color: rgb(143, 91, 158);
  border-color: #8F5B9E;
  background-color: #8F5B9E;
  border: none;
  height: 2px;
}
.contentDividerDesktop{
  width: 100%;
  color: rgb(143, 91, 158);
  border-color: #8F5B9E;
  background-color: #8F5B9E;
  border: none;
  height: 2px;
}

.desktopCardForm{
  width: 50% !important;
  /* aspect-ratio : 1 / 1; */
  background-color: transparent !important;
  border: 1px solid white;
  padding: 50px !important;
  border-radius: 0px !important;
}

.desktopCardForm Input{
  margin: 10px;
}

.desktopCardForm Button{
  margin-top: 20px !important;
}
.desktopFormDiv{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 10%;
}
body{
  overflow: hidden;
}

.root{
  overflow: hidden;
  
}

#root{
  overflow: hidden;
}

html, body {
  /* height: 100vh !important; */
  width: 100vw !important;
}

html, body, .root{
  touch-action: manipulation;
  height: 100%;
  -webkit-text-size-adjust: none;
}

.signUpStepper{
  color: white;
  width: 100vw;
}

*::-webkit-scrollbar {
  display: none;
}
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}