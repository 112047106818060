.paymentOutcomeContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .paymentOutcomeSubtext{
        color: #FFF;
        text-align: center;
        font-size: 15px;
        font-weight: 300;
        margin: 0px;
        margin-bottom: 20px;
    }
    .welcomeUnderline::after{
        left: -1%;
        width: 103%;
    }
}
