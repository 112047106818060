.updateContainer{
    width: 90%;
    height: 300px;
    background-color: #FFFFFF;
    border-radius: 15px;
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;

    .updateCoinImage{
        position: absolute;
        top: -20px;
        left: -4vw;
    }
    .updateImage{
        width: 100%;
        border-radius: 15px;

    }

    .previewContent{
        width: 100%;
        max-width: 100%;
        // height: 40%;
        // max-height: 40%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        p{
            color: #0B132F;
            font-family: Roboto;
            font-size: 15px;
            font-style: normal;
            // font-weight: 300;
            line-height: normal;
            max-width: 95%;
            margin: 0px;
            max-height: 60%;
            overflow: hidden;
            text-align: left;
            display: -webkit-box;
            -webkit-box-orient: vertical; 
            -webkit-line-clamp: 3;
        }
    }
    .readMoreText{
        color: #E43180;
        font-family: Roboto;
        font-size: 15px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-align: right;
        margin-top: 5px;
    }
}

.updateContainerActive{
    width: 90%;
    background-color: #FFFFFF;
    border-radius: 15px;
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;

    .updateCoinImage{
        position: absolute;
        top: -20px;
        left: -4vw;
    }
    .updateImage{
        width: 100%;
        border-radius: 15px;
    }

    .previewContent{
        width: 100%;
        max-width: 100%;
        // height: 40%;
        // max-height: 40%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        p{
            color: #0B132F;
            font-family: Roboto;
            font-size: 15px;
            font-style: normal;
            // font-weight: 300;
            line-height: normal;
            max-width: 95%;
            margin: 0px;
            margin-bottom: 5px;
            // max-height: 60%;
            width: 100%;
            overflow: hidden;
            text-align: left !important;
            // display: -webkit-box;
            // -webkit-box-orient: vertical; 
            // -webkit-line-clamp: 3;
        }
    }
    .readMoreText{
        color: #E43180;
        font-family: Roboto;
        font-size: 15px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-align: right;
        margin-top: 5px;
    }
}