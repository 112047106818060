.adminUserContainer{
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding-left: 68px;
  padding-right: 68px;
  // background-color: green;
  .headerSection{
    height: 12%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1%;
    // background-color: red;
    width: 100%;
    .userHeader{
      color: #FFF;
      font-family: Roboto;
      font-size: 45px;
      font-style: normal;
      font-weight: 900;
      margin: 0px;
      margin-left: 40px;
    }
  }
  .userTable{
    width: 100%;
    height: 70%;
    background-color: #FFFFFF40;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: scroll;
    margin-bottom: 1%;
    .tableHeaderRow{
      width: 95%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 25px;
      p{
        color: #FFF;
        font-family: Roboto;
        font-size: 30px;
        font-style: normal;
        font-weight: 900;
        margin: 0px;
        margin-bottom: 5px;
      }
    }
    .tableHeaderDivider{
      width: 95%;
      height: 3px;
      background-color: #FFFFFF40;
      flex-shrink: 0;
    }

    .tableContentRow{
      width: 95%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 25px;
      cursor: pointer;
      p{
        color: #FFF;
        font-family: Roboto;
        font-size: 25px;
        font-style: normal;
        font-weight: 300;
        margin: 0px;
        margin-bottom: 5px;
      }
    }
    .tableContentDivider{
      width: 95%;
      height: 1px;
      background-color: #FFFFFF40;
      flex-shrink: 0;
    }

  }

  .usersSummaryContainer{
    width: 100%;
    height: 12%;
    // background-color: red;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .userSummaryItem{
      width: 25%;
      height: 90%;
      background-color: #D9D9D940;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .header{
        color: #FFF;
        text-align: center;
        font-family: Roboto;
        font-size: 15px;
        font-style: normal;
        font-weight: 300;
        margin: 0px
      }
      .content{
        color: #FFF;
        text-align: center;
        font-family: Roboto;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        margin: 0px;
      }
    }
  }

  .userDetailContainer{
    width: 100%;
    height: 100%;
    background-color: #00000080;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    .userDetailPopup{
      width: 80%;
      height: 80%;
      border-radius: 15px;
      background: linear-gradient(15deg, #0B132F 5.33%, #006BAC 245.1%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .popupHeader{
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-radius: 15px 15px 0px 0px;
        background: linear-gradient(90deg, #2A6091 0.04%, #E43180 117.78%);
        padding-left: 15px;
        padding-right: 15px;
        img{
          height: 90%;
        }
      }
      .popupContent{
        width: 100%;
        height: 90%;
        overflow: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        .popupRow{
          width: 95%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
        .popupTable{
          width: 95%;
          background-color: #FFFFFF40;
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 50px;
          padding-bottom: 30px;
          .tableHeaderRow{
            width: 95%;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 25px;
            p{
              color: #FFF;
              text-align: center;
              font-family: Roboto;
              font-size: 20px;
              font-style: normal;
              font-weight: 900;
              margin: 0px;
            }
          }
          .tableHeaderDivider{
            width: 95%;
            height: 3px;
            flex-shrink: 0;
            background-color: #FFFFFF40;
          }
          .tableContentRow{
            width: 95%;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 25px;
            p{
              color: #FFF;
              text-align: center;
              font-family: Roboto;
              font-size: 20px;
              font-style: normal;
              font-weight: 300;
              margin: 0px;
            }
          }
          .tableContentDivider{
            width: 95%;
            height: 1px;
            flex-shrink: 0;
            background-color: #FFFFFF40;
          }
        }
      }
    }
  }
}