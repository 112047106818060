.adminTransactionContainer {
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding-left: 68px;
  padding-right: 68px;

  // background-color: green;
  .headerSection {
    height: 12%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1%;
    // background-color: red;
    width: 100%;

    .userHeader {
      color: #FFF;
      font-family: Roboto;
      font-size: 45px;
      font-style: normal;
      font-weight: 900;
      margin: 0px;
      margin-left: 40px;
    }
  }

  .userTable {
    width: 100%;
    height: 70%;
    background-color: #FFFFFF40;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: scroll;
    margin-bottom: 1%;

    .tableHeaderRow {
      width: 95%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 25px;

      p {
        color: #FFF;
        font-family: Roboto;
        font-size: 30px;
        font-style: normal;
        font-weight: 900;
        margin: 0px;
        margin-bottom: 5px;
      }
    }

    .tableHeaderDivider {
      width: 95%;
      height: 3px;
      background-color: #FFFFFF40;
      flex-shrink: 0;
    }

    .tableContentRow {
      width: 95%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 25px;
      cursor: pointer;

      p {
        color: #FFF;
        font-family: Roboto;
        font-size: 25px;
        font-style: normal;
        font-weight: 300;
        margin: 0px;
        margin-bottom: 5px;
      }
    }

    .tableContentDivider {
      width: 95%;
      height: 1px;
      background-color: #FFFFFF40;
      flex-shrink: 0;
    }

  }

  .usersSummaryContainer {
    width: 100%;
    height: 12%;
    // background-color: red;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    .userSummaryItem {
      width: 20%;
      height: 90%;
      background-color: #D9D9D940;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .header {
        color: #FFF;
        text-align: center;
        font-family: Roboto;
        font-size: 15px;
        font-style: normal;
        font-weight: 300;
        margin: 0px
      }

      .content {
        color: #FFF;
        text-align: center;
        font-family: Roboto;
        font-size: 25px;
        font-style: normal;
        font-weight: 700;
        margin: 0px;
      }
    }
  }

  .userDetailContainer {
    width: 100%;
    height: 100%;
    background-color: #00000080;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    .userDetailPopup {
      width: 80%;
      height: 60%;
      border-radius: 15px;
      background: linear-gradient(15deg, #0B132F 5.33%, #006BAC 245.1%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .popupHeader {
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-radius: 15px 15px 0px 0px;
        background: linear-gradient(90deg, #2A6091 0.04%, #E43180 117.78%);
        padding-left: 15px;
        padding-right: 15px;

        img {
          height: 90%;
        }
      }

      .popupContent {
        width: 100%;
        height: 90%;
        overflow: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        border-radius: 0 0 15px 15px;

        .contentHeader {
          width: 94%;
          text-align: left;
          margin: 1% 0 0.25% 0.5%;
          font-weight: 600;
          font-size: 16px;

        }

        .popupRowDetail {
          width: 95%;
          background-color: #FFFFFF40;
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          .header {
            color: #FFF;
            font-family: Roboto;
            font-size: 10px;
            font-style: normal;
            font-weight: 700;
            margin: 0px;
            margin-top: 12px;
            margin-left: 15px;
          }

          .content {
            color: #FFF;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            margin: 0px;
            margin-left: 14px;
            opacity: 0.5;
          }
        }

        .popupRow {
          width: 95%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .statusBlock {
            width: 49%;
            height: 60px;
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #FFFFFF40;

            p {
              color: #FFF;
              text-align: center;
              font-family: Roboto;
              font-size: 30px;
              font-style: normal;
              font-weight: 300;
              margin: 0px;
            }
          }
        }

        .popupTable {
          width: 95%;
          background-color: #FFFFFF40;
          border-radius: 15px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 50px;
          padding-bottom: 30px;

          .tableHeaderRow {
            width: 95%;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 25px;

            p {
              color: #FFF;
              text-align: center;
              font-family: Roboto;
              font-size: 20px;
              font-style: normal;
              font-weight: 900;
              margin: 0px;
            }
          }

          .tableHeaderDivider {
            width: 95%;
            height: 3px;
            flex-shrink: 0;
            background-color: #FFFFFF40;
          }

          .tableContentRow {
            width: 95%;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 25px;

            p {
              color: #FFF;
              text-align: center;
              font-family: Roboto;
              font-size: 20px;
              font-style: normal;
              font-weight: 300;
              margin: 0px;
            }
          }

          .tableContentDivider {
            width: 95%;
            height: 1px;
            flex-shrink: 0;
            background-color: #FFFFFF40;
          }
        }

        .othertrans,
        .compliance {
          width: 95%;
          height: auto;
          margin-top: 0.5%;
        }

        .compliance {
          min-height: 360px !important;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 1%;

          .img_section,
          .body_section {
            display: flex;
            width: 100%;
            min-height: 190px;
            justify-content: space-between;

            .userimg {
              min-width: 190px;
              height: 99%;
              aspect-ratio: 1 / 1;
              border-radius: 50%;
            }

            .imginfo {
              width: 80%;

              .info_p {
                width: 99%;
                margin: 1.4% 0.4% 1.4% 0.4%;
                display: flex;

                .spanleft,
                .spanright {
                  display: flex;
                  justify-content: flex-start;
                  width: 30%;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 300;
                  line-height: normal;
                  opacity: 0.5;
                }

                .spanright {
                  display: flex;
                  justify-content: flex-end;
                  width: 70%;
                  font-weight: 400;
                }
              }
            }
          }

          .body_section {
            // background-color: orange;
            margin: 5px 0px;
            display: flex;

            .leftbody,
            .rightbody {
              // background-color: red;
              min-width: 49% !important;

              .info_p {
                width: 99%;
                margin: 1.4% 0.4% 1.4% 0.4%;
                display: flex;

                .spanleft,
                .spanright {
                  display: flex;
                  justify-content: flex-start;
                  width: 30%;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 300;
                  line-height: normal;
                  opacity: 0.5;
                }

                .spanright {
                  display: flex;
                  justify-content: flex-end;
                  width: 70%;
                  font-weight: 400;
                }
              }
            }

            .rightbody {
              // background-color: green;
            }
          }
        }

        .btn_section {
          width: 80%;
          min-height: 80px;
          display: flex;
          justify-content: space-around;
          align-items: center;
        }
      }
    }

    .filterPopup {
      width: 50%;
      height: 40%;
      border-radius: 15px;
      background: linear-gradient(15deg, #0B132F 5.33%, #006BAC 245.1%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .popupHeader {
        width: 100%;
        height: 15%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-radius: 15px 15px 0px 0px;
        background: linear-gradient(90deg, #2A6091 0.04%, #E43180 117.78%);
        padding-left: 15px;
        padding-right: 15px;

        img {
          height: 90%;
        }
      }

      .filterContent {
        width: 100%;
        height: 85%;
        overflow: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .filterRow {
          width: 95%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }

  .checklist {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;

    .clientFileComplianceChecklist {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .textInputContainer,
      .complianceChecklistTableContainer,
      .complianceHeaderFieldContainers,
      .complianceHeader,
      .ficaHeader,
      .additionalCommentsHeader {
        width: 99%;
      }

    }
  }
}