.circle-container {
    position: relative;
    width: 200px; /* Adjust as needed */
    height: 200px; /* Adjust as needed */

    .open-circle {
        position: absolute;
        width: 100%;
        height: 100%;
        border: 1px solid;
        border-radius: 50%;
        border-bottom: none; /* To keep the bottom open */
        background: linear-gradient(180deg, #006CAC 0%, #E43180 88.02%);
        overflow: hidden;
      }
      
      .p-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
      
      p {
        margin: 0;
        color: #fff; /* Adjust text color as needed */
        display: none;
      }
      
      p.active {
        display: block;
      }
      
  }
  
  