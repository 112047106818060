.Comptrans_line {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0%;
    top: -1%;

    ._pgraphname {
        font-size: 30px;
        font-weight: 900;
        line-height: 35.16px;
        text-align: center;
        height: 4%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 95%;
        margin: 0px 0 3.5% 0;
    }

    .comp_popup {
        position: absolute;
        border-radius: 15px 15px 15px 0px;
        cursor: pointer;
        color: white;
        min-width: 8%;
        min-height: 7%;
        display: block;
        align-items: center;
        justify-content: center;
        width: auto;
        height: auto;
    }

    svg {
        rect {
            fill: rgba(96, 125, 146, 0.351);
            background-color: red;
        }
    }

}