.listingGoBackContainer{
    height: 50%;
    background-color: #0B132F;
    width: 100vw;
    z-index: 1;
    position: relative;
}

.listingInvestContainer{
    height: 50%;
    background-color: #E43180;
    width: 100vw;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    .listingInvestTextContainer{
        width: 100%;
        height: 15%;
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: red;
        p{
            margin: 0px;
            color: #FFF;
            text-align: center;
            font-size: 25px;
            font-weight: 900;
            width: 100%;
        }
    }
}

.listingContainer{
    position: absolute;
    top: 2%;
    z-index: 2;
    width: 100vw;
    height: 90.5%;
    // background-color: green;
    background: linear-gradient(60deg, rgba(13,20,45,1) 37%, rgba(17,44,66,1) 85%);
    border-top-left-radius: 100px;
    border-bottom-right-radius: 100px;
    // display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: scroll;
    overflow-x: hidden;

    .listingTeamImageContainer{
        width: 100%;
        border-top-left-radius: 100px;
        position: relative;
        margin-bottom: 20px;
        img{
            width: 100%;
            border-top-left-radius: 100px;
        }
    }

    .listingTags{
        width: 100vw;
        display: flex;
        flex-direction: row;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        // align-items: center;
        justify-content: space-between;
        overflow-x: scroll;
        white-space: nowrap; 
        // margin-bottom: 20px;
        .listingTagsItem{
            height: 30px;
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFF;
            margin-left: 5px;
            margin-right: 5px;
            background-color: #D9D9D940;
            p{
                margin-top: 0px;
                margin-bottom: 0px;
                font-size: 15px;
                font-weight: 300;
            }
        }
    }

    .listingDescription{
        color: #FFF;
        font-size: 15px;
        font-weight: 300;
        text-align: left;
        margin: 0px;
        margin-left: 25px;
        margin-right: 25px;
        margin-bottom: 20px;
    }
    .listingSocialMediaContainer{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        margin-bottom: 20px;

        .listingSocialMediaItem{
            width: 35px;
            height: 35px;
            background-color: #FFF;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 15%;
        }
    }

    .listingTeamContainer{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-evenly;
        overflow-y: hidden;
        overflow-x: scroll;
        margin-bottom: 20px;
        padding: 0px;
        margin-top: 10px;
        .listingTeamMember{
            min-width: 30%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            text-decoration: none;
            margin-left: 5px;
            margin-right: 5px;
            width: 30%;
            img{
                width: 100%;
            }
            .listingTeamMemberName{
                margin: 0px;
                color: #FFF;
                text-align: center;
                font-size: 15px;
                font-weight: 300;
                text-decoration: none;
            }
            .listingTeamMemberRole{
                margin: 0px;
                color: #FFF;
                text-align: center;
                font-size: 13px;
                font-weight: 700;
            }
        }
    }

    .listingChallengeSolution{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        overflow-x: scroll;
        margin-bottom: 20px;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;
    }
    .listingChallengeSolution::-webkit-scrollbar{
        display: none;
    }

    .listingSectionHeader{
        color: #FFF;
        font-size: 20px;
        font-weight: 900;
        text-align: left;
        margin: 0px;
        margin-bottom: 0px;
        margin-left: 25px;
    }

    .listingOptionsContainer{
        width: 100%;
        display: flex;
        margin-bottom: 20px;

        .listingOptionsLeft{
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .listingOptionsRight{
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}
