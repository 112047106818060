.reportParent {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0% 0.5% 0% 0.5%;
    overflow-y: scroll;
    align-items: center;
    position: relative;

    .datestring {
        height: 10%;
        width: 98%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .repImg {
            width: 2.5%;
            aspect-ratio: 1 / 1;
            border-radius: 12px;
            border-style: unset;
            border-color: transparent;
            padding: 0.3%;
            cursor: pointer;
            background: rgba(255, 255, 255, 0.25);
            margin-left: 1%;
        }

        .reptext {
            font-size: 30px;
            font-weight: 400;
            line-height: 35.16px;
            text-align: center;
            display: flex;
            height: 80%;
            align-items: center;
            width: 75%;

            .p_ {
                font-size: 30px;
                font-weight: 900;
                line-height: 35.16px;
                text-align: left;
                margin-left: 5.5px;
            }
        }

        .token_div {
            display: flex;
            width: 5%;
            height: 100%;
            flex-direction: column;

            .p_token {
                font-size: 25px;
                font-weight: 400;
                line-height: 29.3px;
                text-align: left;
                width: 90%;
                background: rgba(217, 217, 217, 0.25);
                border-radius: 10px;
                padding-left: 10px;
                height: 84%;
                display: flex;
                align-items: center;
                margin: 1% 4% 0 0%;

                span {
                    font-size: 25px;
                    font-weight: 700;
                    line-height: 29.3px;
                    text-align: left;
                }
            }

        }

        .genreport,
        .genrepor_active {
            font-size: 20px;
            font-weight: 700;
            line-height: 29.3px;
            text-align: center;
            height: 95%;
            background: #D42E79;
            display: flex;
            align-items: center;
            cursor: pointer;
            color: white;
            width: 12%;
            justify-content: center;
            border-radius: 15px;
            margin-right: 0.5%;
        }

        a {
            // margin-right: 0.5%;
            // width: 12.5%;
            // display: flex;
            // align-items: center;
            // justify-content: center;

            .genrepor_active {
                // width: 100% !important;
                // height: 4.5vh !important;
            }
        }

        .popup_div {
            position: absolute;
            border-radius: 15px;
            background: rgba(31, 52, 70, 0.86);
            height: 55%;
            width: 20%;
            top: 11%;
            left: 65.8%;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            z-index: 1;

            .popup_item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0.5%;
                flex-direction: row;
                padding: 0% 5% 0% 0%;
                cursor: pointer;

                .p_popup,
                .p_popupSelected {
                    color: #FFF;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;

                }

                .p_popupSelected {
                    font-weight: 900;
                }

                .span_popup,
                .span_popupSelected {
                    background-position: center;
                    background-repeat: no-repeat;
                    background-image: url('./images/notSelected.svg');
                    width: 12%;
                    height: 100%;
                    cursor: pointer;
                }

                .span_popupSelected {
                    background-image: url('./images//Subtract.svg');
                    background-repeat: no-repeat;
                    background-position: center;

                    .tick {
                        height: 100%;
                        background-size: 45% 70%;
                        width: 96%;
                        margin: -3% 0 0 2.5px;
                        background-image: url('./images//tick.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                    }

                    .notick {
                        visibility: hidden;
                    }
                }
            }
        }

    }

    a {
        // position: absolute;
        // top: 0%;
        // left: 86.3%;
        // font-size: 22px;
        // font-weight: 600;
        // background: #D42E79;
        // width: 11.7%;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // height: 6.5%;
        // border-radius: 15px;
        // color: white;
        // text-decoration: none;
    }

    .reportsection {
        min-height: 85%;
        width: 100%;
        display: flex;
        //    background: gainsboro;
        flex-direction: column;
        align-items: center;
        padding: 0% 0% 0.5% 0%;

        .compdiv_cover{
            height: 250px;
            width: 96%;
            margin: 2%;
          //  background: green;
            display: flex;
            justify-content: center;
        
        }
    }

    .document {
        width: 100vw;
        height: 30vh;
    }
}