.videoAdminContainer{
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .descriptionBox{
        width: 55%;
        height: 200px;
        border-radius: 15px;
        background-color: #FFFFFF40;
        padding: 12px;
        .descHeading{
            color: #FFF;
            font-family: Roboto;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            width: 100%;
            text-align: left;
            height: 12%;
            margin: 0px;
        }

        .descTA{
            width: 100%;
            height: 88%;
            resize: none;
            background: transparent;
            border: none;
            outline: none;
            color: #FFF;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            padding: 0px;
        }
        .descTA::placeholder{
            color: #FFFFFF80;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }
    }

    .linkBox{
        width: 40%;
        height: 200px;
        border-radius: 15px;
        background-color: #FFFFFF40;
        padding: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        .linkHeading{
            color: #FFF;
            font-family: Roboto;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            width: 100%;
            text-align: left;
            height: 12%;
            margin: 0px;
        }
    }
}

.bottomButtonContainer{
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}