.complianceDetail{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    border-top-left-radius: 200px;
    background: linear-gradient(28deg, #0B132F 15.43%, #006BAC 252.83%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;

    .infoDiv{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        .idverf{
            width: 250px;
            height: 250px;
            background-color: #485167;
            margin-left: 40px;
            margin-top: 40px;
            border-radius: 50%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            .authVerf{
                color: #FFF;
                text-align: center;
                font-family: Roboto;
                font-size: 27px;
                font-style: normal;
                font-weight: 700;
                margin: 0px;
            }
            .authVerfImg{
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
        .userInfo{
            width: calc(100% - 300px - 20px);
            margin-top: 40px;
            padding: 10px;
            .userInfoLine{
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-top: 10px;
                .lbl{
                    color: #FFF;
                    font-family: Roboto;
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    margin: 0px;
                }
                .val{
                    color: #FFF;
                    text-align: right;
                    font-family: Roboto;
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin: 0px;
                }
            }
        }
    }
    .metadataDiv{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-evenly;
        margin-top: 50px;
        .metadataInfo{
            width: 45%;
            .header{
                color: #FFF;
                text-align: center;
                font-family: Roboto;
                font-size: 27px;
                font-style: normal;
                font-weight: 700;
                margin: 0px;
                width: 100%;
                text-align: left;
            }
            .metadataLine{
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-top: 10px;
                .lbl{
                    color: #FFF;
                    font-family: Roboto;
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    margin: 0px;
                }
                .val{
                    color: #FFF;
                    text-align: right;
                    font-family: Roboto;
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin: 0px;
                }
            }
        }
        .transactionInfo{
            width: 45%;
            .header{
                color: #FFF;
                text-align: center;
                font-family: Roboto;
                font-size: 27px;
                font-style: normal;
                font-weight: 700;
                margin: 0px;
                width: 100%;
                text-align: left;
            }
            .transactionLine{
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-top: 10px;
                .lbl{
                    color: #FFF;
                    font-family: Roboto;
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    margin: 0px;
                }
                .val{
                    color: #FFF;
                    text-align: right;
                    font-family: Roboto;
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin: 0px;
                }
            }
        }
    }
    .buttonDiv{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-evenly;
        margin-top: 50px;
    }
}