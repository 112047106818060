.DesktopPortfolioCard{
    width: 94%;
    background-color: #FFFFFF40;
    border-radius: 15px;
    border-top-left-radius: 0px;
    margin-bottom: 35px;
    display: flex;
    flex-direction: row;
    position: relative;
    .companyCoin{
        position: absolute;
        width: 55px;
        height: 55px;
        top: -15px;
        left: -15px;
    }

    .portfolioCardDetailsContainer{
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 15px;
        .currentValueHeader{
            margin: 0px;
            color: #FFF;
            text-align: center;
            font-family: Roboto;
            font-size: 25px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }
        .currentValueAmount{
            color: #FFF;
            text-align: center;
            font-family: Roboto;
            font-size: 45px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            margin: 0px;
        }
        .currentValuePerc{
            margin: 0px;
            color: #47FF00;
            text-align: center;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .investmentDetails{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;
            margin-top: 20px;
            .investmentDetail{
                width: calc(50% - 1px);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                padding-top: 10px;
                padding-bottom: 10px;
                .investmentDetailHeader{
                    margin: 0px;
                    color: #FFF;
                    text-align: center;
                    font-family: Roboto;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }
                .investmentDetailAmount{
                    margin: 0px;
                    color: #FFF;
                    text-align: center;
                    font-family: Roboto;
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
                .investmentDetailPercent{
                    margin: 0px;
                    color: #47FF00;
                    text-align: center;
                    font-family: Roboto;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }
            .investmentDivider{
                background-color: #FFFFFF40;
                height: 100%;
                width: 2px
            }
        }
        .WithdrawalDate{
            margin: 0px;
            color: #FFF;
            text-align: center;
            font-family: Roboto;
            font-size: 13px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            margin-top: 20px;
        }
    }

    .portfolioCardUpdatesContainer{
        width: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .updateContainer{
            width: 90%;
            height: 95%;
            border-radius: 15px;
            background-color: #FFFFFF;
            img{
                width: 100%;
                border-radius: 15px;
            }
            p{
                margin: 0px;
                margin-top: 5px;
                color: #0B132F;
                font-family: Roboto;
                font-size: 20px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                span{
                    font-weight: 900;
                    color: #E43180;
                }
            }
        }
    }
}