.AEXCompliance{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    border-top-left-radius: 200px;
    background: linear-gradient(28deg, #0B132F 15.43%, #006BAC 252.83%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;


    .AEXComplianceSelectorContainer{
        width: 80%;
        height: 66px;
        background-color: #D9D9D940;
        border-radius: 15px;
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        .AEXComplianceSelectorOption{
            width: 49%;
            height: 90%;
            background-color: #FFFFFF40;
            border-radius: 15px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            p{
                color: #FFF;
                text-align: center;
                font-family: Roboto;
                font-size: 30px;
                font-style: normal;
                font-weight: 900;
                line-height: normal;
                margin: 0px;
            }
        }
    }

    .header{
        margin: 0px;
        color: #FFF;
        font-family: Roboto;
        font-size: 45px;
        font-style: normal;
        font-weight: 900;
        margin-left: 100px;
        margin-top: 35px;
        line-height: normal;
        align-self: flex-start;
    }

    .AEXComplianceTable{
        width: 90%;
        height: 20px;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        .AEXComplianceTableHeader{
            width: 98%;
            display: flex;
            flex-direction: row;
            align-items: center;
            .AEXComplianceTableHeaderHeading{
                margin: 0px;
                color: #FFF;
                font-family: Roboto;
                font-size: 30px;
                font-style: normal;
                font-weight: 900;
                line-height: normal;
                text-align: left;
            }
        }
        .AEXComplianceTableHeaderDivider{
            width: 100%;
            min-height: 3px;
            background-color: #FFFFFF40;
            margin-top: 13px;
        }
        .AEXComplianceTableItem{
            min-width: 100%;
            min-height: 61px;
            background-color: #D9D9D940;
            margin-top: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            
            .AEXComplianceTableItemContainer{
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 100%;
                width: 98%;
                .arrowDiv{
                    width: 10%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;
                    *{
                        color: white;
                        font-size: 25px;
                    }
                }
            }
            p{
                margin: 0px; 
                color: #FFF;
                font-family: Roboto;
                font-size: 25px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-align: left;
            }
        }
    }
    .AEXComplianceSelectedUserContainer{
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        border-top-left-radius: 200px;
        background: linear-gradient(28deg, #0B132F 15.43%, #006BAC 252.83%);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        .infoRow{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            margin-top: 30px;

            .imageCircle{
                background-color: #485167;
                width: 15%;
                aspect-ratio: 1/1;
                border-radius: 50%;
                margin-left: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 99%;
                    height: 99%;
                    border-radius: 50%;
                }
                p{
                    margin: 0px;
                    color: #FFF;
                    text-align: center;
                    font-family: Roboto;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

            }
            .infoRect{
                width: 70%;
                margin-right: 60px;
                .infoLine{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 10px;
                    .infoKey{
                        margin: 0px;
                        color: #FFF;
                        font-family: Roboto;
                        font-size: 25px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        text-align: left;
                    }
                    .infoVal{
                        margin: 0px;
                        color: #FFF;
                        text-align: right;
                        font-family: Roboto;
                        font-size: 25px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        text-align: right;
                    }
                }
            }
        }
    }
}