.DesktopPortfolioTopRankingInvestments{
    width: 95%;
    border-radius: 15px;
    background-color: #FFFFFF40;
    padding: 30px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .headerText{
        margin: 0px;
        color: #FFF;
        font-family: Roboto;
        font-size: 35px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
    }
    .topInvestmentContainer{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        .topInvestmentContainerLeft{
            display:flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            img{
                width: 60px;
                height: 60px;
                margin-right: 5px;
            }
            div{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                p{
                    margin: 0px;
                    text-align: left;
                }
                p:first-child{
                    color: #FFF;
                    font-family: Roboto;
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: normal;
                }
                p:last-child{
                    color: #FFF;
                    font-family: Roboto;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                }
            }
        }
        .percentInc{
            margin: 0px;
            color: #47FF00;
            text-align: right;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }
    .topRankingDividers{
        width: 100%;
        height: 2px;
        background-color: #FFFFFF40;
        margin-top: 35px;
    }

    .fundsToInvest{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        .fundsHeader{
            margin: 0px;
            color: #FFF;
            font-family: Roboto;
            font-size: 25px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }
        .fundsAmount{
            margin: 0px;
            color: #FFF;
            text-align: right;
            font-family: Roboto;
            font-size: 25px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
        }
    }
}