.line {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 80%;

    ._p {
        font-size: 30px;
        font-weight: 900;
        line-height: 35.16px;
        text-align: center;
        height: 4%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 95%;
        margin: 0px 0 3.5% 0;
    }

    .popup {
        position: absolute;
        border-radius: 15px 15px 15px 0px;
        cursor: pointer;
        color: white;
        min-width: 8%;
        min-height: 7%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}