

.amountDiv{
    border: 8px solid #404952;
    border-radius: 15px;
    // filter: drop-shadow(0px 0px 4px black);
    margin-right: 5px;
  
    p{
      color: #404952
    }
  }
  
  .stakeDiv{
    border: 8px solid #404952;
    border-radius: 15px;
    // filter: drop-shadow(0px 0px 4px black);
    margin-left: 5px;
  
    p{
      color: #404952
    }
  }
  
  .buyCompanyLogoImage{
    // width: 150px;
    width: 100px;
    margin-left: 10px;
    margin-top: 10px;
    filter: drop-shadow(1px 1px 3px black);
  }

  .buyCloseButton{
    width: 40px;
    margin-right: 10px;
    margin-top: 10px;
  }

  .buyImageDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

  }
  .dchart-innertext-value {
    display: none;
  }

  .buyInp{
    width: 60vw;
  }

  .buyCalcDiv{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .buyInputDiv{
    // display: flex;
    // position: relative;
    // top: 95px;
    margin-left: 5px;
    p{
      margin-top: 0px;
      margin-bottom: 2px;
    }
    input{
      margin-bottom: 0px;
    }
    Button{
      position: absolute;
      right: 35vw;
    }
  }
  .buyCompBtn{
    width: 30vw !important;
    // height: 40px !important;
    // margin-top: 0px !important;
    // margin-bottom: 0px !important;
    // margin-left: 15px !important;
  }
  
  .amountStakeDiv{
    margin-top: 10px;
    p{
      color: white;
      font-weight: bold;
      margin-top: 10px;
      margin-left: 5px;
      margin-right: 0px;
    }
  }
  
  .wygSubtitle{
    font-size: 12px;
    text-align: center;
  }
  
  .CompanyBuyCard{
    // display: flex;
    justify-content: flex-start;
    background-size: cover;
    background-blend-mode: multiply;
    position: fixed;
    z-index: 999;
    // background-color: #908BC3;
    // border-top-left-radius: 25px;
    // border-top-right-radius: 25px;
    // height: 300px;
    width: 100%;
    // margin-top: 15px;
    margin-bottom: 15px;
    // margin-left: 15px;
    // margin-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .compImgBut{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
    justify-content: space-between;
  }
  
  .compButton{
    width: 150px !important;
    height: 30px !important;
    min-height: 0px !important;
    padding: 0px !important;
    border: none !important;
    margin-right: 10px !important;
  }

  .remainingP{
    font-size: 29px;
  }

.descP{
  text-align: left;
}

.whatYouGet{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 10px;
  // width: 20vw;
  margin: 0px;
  // width: 100% !important;
  // height: 113px;
  .wygMainInfo{
    font-weight: bold;
    font-size: 40px;
    
  }
  p{
    margin-left: 4px;
    margin-right: 4px;
    margin-top: 0px;
    margin-bottom: 0px;
    // font-weight: lighter;
  }
}

.wygmainDiv{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 94vw;
  align-items: flex-end;
  // margin-left: 25px;
  margin-right: 3vw;
  margin-left: 3vw;
  margin-top: 80px;

  div{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }
}

.wygmainDivCar{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 5vh;
}
.carousel-root{
  position: unset !important;
  margin-top: 10px;
}

.buyInput{
  margin: 0px;
  border: none;
  text-align: left;
  padding: 0px;
  outline: none;
}

.buyIcon{
  font-size: 30px;
}

.buyIconDiv{
  // border-right: 1px solid white;
  width: 20%;
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buyInputDiv{
  border: 1px solid white;
  display: flex;
  align-items: center;
}



.inputFieldset{
  padding: 0px;
  display: flex;

  legend{
    margin-left: 10px;
    color: white;
    z-index: 999;
  }
}

.buyProgress{
  height: 80px;
}

.wygInfoDivider{
  margin-bottom: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 80%;
  display: flex;
  justify-content: center;

  p{
    font-size: 30px !important;
    transform: scale(1,1.3);
  }
}