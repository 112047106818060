.blurydiv {
    position: fixed;
    background-color: #e1eef66e;
    height: 100%;
    width: 100%;
    top: 0%;
    left: 0%;
    z-index: 5;

    .error {
        position: absolute;
        height: 130px;
        width: 350px;
        border-radius: 15px;
        background: linear-gradient(15.39deg, #0B132F 5.33%, #006BAC 245.1%);
        top: 35%;
        left: 35%;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;

        .err_btnokay {
            border-radius: 13px;
            background: rgba(212, 46, 121, 1);
            font-size: 25px;
            font-weight: 700;
            width: 30%;
            line-height: 29.3px;
            text-align: center;
            cursor: pointer;
            margin: 2px;
        }

        .errmessage {
            color: rgb(255, 89, 89);
            height: 30%;
            font-size: 15px;
            font-weight: 300;
            text-align: left;
            margin: 2px;
        }
    }

    .popup {
        background: linear-gradient(15.39deg, #0B132F 5.33%, #006BAC 245.1%);
        height: 41%;
        width: 40%;
        position: absolute;
        top: 30%;
        left: 30%;
        border-radius: 15px;
        display: flex;
        flex-direction: column;

        .popuptop {
            background: linear-gradient(90.04deg, #2A6091 0.04%, #E43180 117.78%);
            height: 10%;
            width: 100%;
            border-radius: 15px 15px 0px 0px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0 2%;

            .img_close,
            .img_logo {
                width: 4%;
                cursor: pointer;
                height: 46%;
                aspect-ratio: 1 / 1;
            }

            .img_logo {
                width: 7%;
                height: 95%;
            }
        }

        .popu_body {
            background: linear-gradient(15.39deg, #0B132F 5.33%, #006BAC 245.1%);
            height: 100%;
            display: flex;
            flex-direction: column;
            border-radius: 15px;
            justify-content: space-evenly;
            align-items: center;
            padding: 3.5%;
            .body_right,
            .body_left {
                height: 50%;
                width: 96%;
                margin: 2%;
            }

            .body_left {
                height: 30%;
                display: flex;
                flex-direction: column;
                margin-top: 0%;

                .left_bdy {
                    display: flex;
                    flex-direction: row;
                    height: 100%;
                    justify-content: space-between;
                    width: 100%;
                    margin: 0% 0 0 0;

                    .textInputContainer {
                        height: 75px;
                        width: 49.3%;
                        p {
                            font-size: 16px;
                            margin: -0.25% 0 0.5%;
                        }
                    }

                }
            }

            .body_right {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0;
                position: relative;
                height: 70%;
                margin-top: 1%;

                .rbDatefilters {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    margin: 0;

                    .top_rbDatefilters,
                    .bottom_rbDatefilters {
                        min-height: 50%;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;

                        p {
                            font-size: 25px;
                            font-weight: 700;
                            line-height: 29.3px;
                            text-align: center;
                            background: rgba(255, 255, 255, 0.25);
                            border-radius: 15px;
                            width: 49.3%;
                            height: 85%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin: 0;
                            cursor: pointer;
                        }
                    }
                }

                .p_filter_com,
                .p_filter_stat {
                    top: 6%;
                    margin: 0px;
                    position: absolute;
                    left: 2.2%;
                    font-size: 15px;
                    font-weight: 700;
                    text-align: left;
                }

                .p_filter_stat {
                    // background-color: green ;
                    top: 20%;
                }
            }

            .buttons {
                display: flex;
                flex-direction: row;
                height: 14%;
                align-items: center;
                width: 96%;
                justify-content: center;
              //  background: rgba(102, 51, 153, 0.562);
                margin: 3% 0 1.3% 0;

                .btnapply,
                .btnclear {
                    display: flex;
                    font-size: 25px;
                    font-weight: 700;
                    line-height: 29.3px;
                    text-align: center;
                    background: rgb(212, 46, 121);
                    border-radius: 13px;
                    width: 32%;
                    height: 98%;
                    align-items: center;
                    justify-content: center;
                    margin: 0.7%;
                    cursor: pointer;
                }

                .btnclear {
                    background: rgba(255, 255, 255, 0.25);
                }
            }
        }
    }

    .userparent {
        background: linear-gradient(15.39deg, #0B132F 5.33%, #006BAC 245.1%);
        height: 95%;
        width: 90%;
        position: absolute;
        top: 1%;
        left: 5%;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        overflow: hidden;

        .usertop {
            background: linear-gradient(90.04deg, #2A6091 0.04%, #E43180 117.78%);
            height: 7%;
            width: 100%;
            border-radius: 15px 15px 0px 0px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .img_close,
            .img_logo {
                height: 95%;
                width: 4%;
            }

            .img_logo {
                width: 7%;
            }
        }

        .userbody {
            height: 95%;
            width: 95%;
            // background-color: #ddd;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 2%;

            .userbasics,
            .transactioninfo,
            .transactioninf_0 {
                display: flex;
                flex-direction: row;
                height: 30%;
                width: 100%;
                // background-color: rgb(70, 58, 43);
                flex-wrap: wrap;
                justify-content: space-evenly;
                align-content: center;
                margin-top: 1%;

                .textInputContainer {
                    width: 49%;
                    height: 33%;
                    border-radius: 15px;
                    background-color: rgba(255, 255, 255, 0.25);
                    padding-left: 10px;
                    padding-right: 10px;
                    padding-top: 5px;
                    margin-top: 5px;
                    margin-bottom: 5px;

                    p {
                        height: 20%;
                        margin: 0px;
                        font-size: 15px;
                        font-weight: 700;
                        text-align: left;
                        margin-top: 3px;
                        line-height: 17.58px;
                    }

                    input {
                        height: 65%;
                        padding: 0px;
                        border: 0px;
                        width: 100%;
                        color: #FFF;
                        outline: none;
                        opacity: 0.5;
                        margin: 0% 0 0 0%;
                        font-size: 20px;
                        font-weight: 300;
                        line-height: 23.44px;
                        text-align: left;
                    }
                }

                .user_status {
                    font-size: 30px;
                    font-weight: 300;
                    line-height: 35.16px;
                    text-align: center;
                    color: white;
                    background-color: rgba(255, 255, 255, 0.25);
                    width: 49%;
                    height: 33%;
                    border-radius: 15px;
                    margin: 5px 0 0 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .transactioninfo {
                // background-color: plum;
                display: flex;
                flex-wrap: nowrap;
                height: 12%;
                align-items: center;
                margin: 3px 0 -3px 0;

                .textInputContainer {
                    height: 95%;
                    width: 32.5%;
                }
            }

            .transactioninf_0 {
                //     background-color: indianred;
                justify-content: space-around;
                height: 15%;
                margin: 0;

                .textInputContainer {
                    height: 95%;
                    width: 49%;
                }
            }

            .textinput_extended,
            .textinput_extended_ {
                //   background-color: rgb(46, 46, 19);
                width: 99.2%;
                height: 25%;
                margin: 0 0 0 0;
                border-radius: 15px;
                padding: 2px 0px 1px;
                background: rgba(255, 255, 255, 0.25);
                overflow: hidden;

                ._p {
                    font-size: 15px;
                    font-weight: 700;
                    line-height: 17.58px;
                    text-align: left;
                    //  background-color: #E43180;
                    height: 11%;
                    margin: 8px 0 0 10px;
                    display: flex;
                    align-items: center;
                }

                .mapsec {
                    //        background-color: aquamarine;
                    height: 90%;
                    overflow: scroll;
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: column;

                    p {
                        font-weight: 300;
                        line-height: 23.44px;
                        margin: 3px 0 0 11px;
                        color: #FFF;
                        outline: none;
                        opacity: 0.5;
                        font-size: 20px;
                    }
                }
            }

            .textinput_extended_ {
                width: 49.2%;
                height: 85%;

                ._p {
                    height: 17%;
                    margin: 5px 0 0 10px;
                }

                .mapsec {
                    p {
                        margin: 2% 0 0 11px;
                    }
                }
            }

            .btndiv {
                width: 100%;
                height: 10%;
                display: flex;
                align-self: center;
                //     background-color: grey;
                align-items: center;
                justify-content: center;

                .btnRes,
                .btnFail {
                    font-size: 25px;
                    font-weight: 700;
                    line-height: 29.3px;
                    text-align: center;
                    background: rgb(212, 46, 121);
                    border-radius: 15px;
                    height: 70%;
                    width: 18%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 35px 100px 0 100px;
                    cursor: pointer;
                }
            }
        }
    }
}