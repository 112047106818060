.listingCard{
     width: 100vw;
     height: 260px;
     position: relative;
     z-index: 1;
     margin-bottom: -15px;
     .listingCardImage{
        width: 100vw;
        height: 200px;
        
     }

     .listingCardDesc{
        width: 65%;
        height: 65px;
        border-radius: 0px 15px 15px 15px;
        background-color: #fff;
        position: absolute;
        bottom: 25px;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 15px;
        padding-right: 15px;

        .listingCardDescName{
            color: #0B132F;
            font-size: 20px;
            font-weight: 900;
            margin: 0px;
            text-align: left;
        }

        .listingCardDescIndustry{
            margin: 0px;
            color: #0B132F;
            font-size: 15px;
            font-weight: 300;
            text-align: left;
        }
     }
     .listingCardReturns{
        width: 30%;
        height: 50px;
        position: absolute;
        background-color: #000000A6;
        top: 4%;
        left: 2%;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        p{
            margin: 0px;
            color: #FFF;
            text-align: center;
            font-size: 13px;
            font-weight: 900;
        }
     }

     .returnsGuaranteedSymbol{
        width: 60px;
        height: 60px;
        position: absolute;
        bottom: 55px;
        left: 58%;
        filter: drop-shadow(5px 5px 10px #000000);
     }
}


.listingCardSelected{
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    top: 0px;

    .listingCardSelectedOverlay{
        background-color: #000000BF;
        width: 100vw;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        .listingCardSelectedOverlayText{
            margin: 0px;
            color: #FFF;
            text-align: center;
            font-size: 15px;
            font-weight: 900;
            width: 80%;
        }
    }
    .listingCardDesc{
        background-color: #E43180;
        display: flex;
        align-items: center;
        justify-content: center;
        .listingCardDescName{
            color: #FFF;
            font-size: 20px;
            font-weight: 900;
            text-align: center;
        }
    }
}