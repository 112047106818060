.portfolioCard{
  transition: width 0.2s, height 0.2s, position 0.7s, background-color 0.7s;
  width: 100%;
  height: 100px;
  background-color: #0000001A !important;
  border: none !important;
  display: flex;
  flex-direction: row;
  padding-top: 15px;
  position: relative;
  margin-bottom: 50px;

  .portfolioHeader{
    margin: 0px;
    color: #0B132F;
    text-align: center;
    font-size: 13px;
    font-weight: 300;
  }
  .portfolioContent{
    margin: 0px;
    color: #0B132F;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
  }
  .currentValueContainer{
    width: 50%;
    height: 100%;
  }
  .profitContainer{
    width: 50%;
    height: 100%;
    .portfolioProfit{
      margin: 0px;
      color: #6FDB45;
      text-align: center;
      font-size: 13px;
      font-weight: 700;
    }
  }

  .portfolioBanner{
    position: absolute;
    width: 70%;
    height: 60px;
    background-color: #0B132F;
    border-radius: 0px 15px 15px 15px;
    bottom: -30px;
    left: 0px;
    padding-left: 15px;

    .portfolioBannerHeader{
      margin: 0px;
      color: #FFF;
      font-size: 20px;
      font-weight: 900;
      text-align: left;
      margin-top: 3px;
    }
    .portfolioBannerCategory{
      margin: 0px;
      color: #FFF;
      font-size: 15px;
      font-weight: 300;
      text-align: left;
    }
  }
}

.portfolioCardActive{
  width: 100vw;
    height: calc(100vh - 50px);
    position: fixed;
    top: 50px;
    left: 0px;
    background-color: #0B132F !important;
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    padding: 0px;
    z-index: 2;
    border: none;
    overflow-y: scroll;

    .portfolioSelectedHeader{
      width: 100%;
      min-height: 20%;
      margin-top: 2%;
      border-top-left-radius: 100px;
      background: linear-gradient(79deg, #006BAC 19.17%, #E52E80 82.6%);
      margin-bottom: -3%;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
          width: 50%;
      }
    }
    .portfolioSelectedBody{
        width: 100%;
        // height: 83%;
        height: auto;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        background-color: #FAFAFA;
        // padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        // overflow-y: scroll;
        padding: 20px;
        .portfolioSelectedBodyHeader{
          width: 100%;
          background-color: #D9D9D980;
          padding: 15px;
          margin-bottom: 20px;
          position: relative;
          margin-bottom: 45px;
          .portfolioSelectedBodyHeaderTitle{
            margin: 0px;
            color: #0B132F;
            text-align: center;
            font-size: 13px;
            font-weight: 300;
          }
          .portfolioSelectedBodyHeaderMainContent{
            margin: 0px;
            color: #0B132F;
            text-align: center;
            font-size: 30px;
            font-weight: 700;
          }
          .portfolioSelectedBodyProfitSection{
            width: 100%;
            display: flex;
            flex-direction: row;
            margin-top: 15px;
            .portfolioSelectedBodyHeaderContent{
              margin: 0px;
              color: #0B132F;
              text-align: center;
              font-size: 20px;
              font-weight: 700;
            }
            .profitPerc{
              margin: 0px;
              color: #6FDB45;
              text-align: center;
              font-size: 13px;
              font-weight: 700;
            }
          }
          .withdrawalDate{
            margin: 0px;
            color: #0B132F;
            text-align: center;
            font-size: 13px;
            font-weight: 300;
            margin-top: 15px;
            margin-bottom: 25px;
          }
          .investMoreDiv{
            position: absolute;
            width: 50%;
            height: 45px;
            left: 0;
            background-color: #E43180;
            border-radius: 0px 15px 15px 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            p{
              color: #FFF;
              text-align: center;
              font-size: 20px;
              font-weight: 700;
              margin: 0px;
            }
          }
        }
    }
}