.adminUserDiv{
    border: 1px solid white;
    border-radius: 15px;
    width: 90%;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.adminUserDetail{
    position: absolute;
    width: 75%;
    height: 80%;
    border: 1px solid white;
    border-radius: 15px;
    background-color: #8F5B9E;
    // top: 5%;
    overflow: scroll;

    z-index: 1000000;

    .adminUserInfoSect{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: left;
    }
}

.closeX{
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}