.pdf_parent {
    background-color: #0B132F !important;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    // position: absolute;

    .comp_div,
    .comp_div_image {
         background-color: #0B132F;
        position: relative;
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;
        height: auto;
        display: flex;
        flex-direction: column;
        //   border-radius: 15px;

        .spacer{
            top: 95%;
            background-color: #0B132F;
            color: transparent;
            width: 100%;
            margin: 0%;
            position: absolute;
            left: 0%;
            height: 30%;
        }
        .table_cover {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50%;
            justify-content: flex-start;

            .token_ptag {
                //     background-color: #0B132F;
                width: 100%;
                margin: 3% 0 0 0;
                font-size: 45px;
                font-weight: 900;
                padding-left: 1%;
                display: flex;
            }

            .table {
                width: 96%;
                height: 90%;
                background-color: rgba(217, 217, 217, 0.2509803922);
                border-radius: 15px;
            }
        }

        .conversion {
            display: flex;
            flex-direction: column;
            margin: 1.5% 0%;
            width: 100%;
            height: 525px;
            overflow: hidden;
            position: relative;


            .conversion_top,
            .conversion_bottom {
                //   background-color: rgb(128, 105, 0);
                width: 100%;
                height: 50%;
            }

            .conversion_bottom {
                //      background-color: rgb(124 0 128 / 31%);
                // position: absolute;
                //   top: 45%;
                // left: -112%;
            }
        }

        .date_ptag {
            font-weight: 300;
            font-size: 32px;
            z-index: 2;
            margin: 1% 0px 0% 75%;
        }

        .singuptxt {
            font-size: 40px;
            font-weight: 900;
            line-height: 52.73px;
            text-align: left;
            // background: greenyellow;
            margin: 1%;
        }

        .figure_display {
            overflow: hidden;
            height: 240px;
            display: flex;
            width: 100%;
            position: relative;
            margin-top: 0%;
            margin-bottom: 0%;
            align-items: center;

            .figure_aux {
                width: 100%;
                height: 100%;
            }
        }

        .linecomp,
        .pieNbar_div {
            background-size: 98% 97% !important;
            margin: 0% 0 1% 0;
            min-height: 35vh;
            background-position: center center !important;
            background-repeat: no-repeat !important;
            min-width: 100%;
            border-radius: 15px;
            background: rgba(217, 217, 217, 0.2509803922);
            position: relative;
        }

        .graph_desc_p {
            font-size: 18px;
            font-weight: 900;
            text-align: right;
            margin: -0.5%;
            padding-right: 5%;
        }

        .pieNbar_div {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            background-color: transparent;
            min-height: 350px;

            .the_pie,
            .the_bar {
                //              background-color: red;
                min-height: 99%;
                margin: 1%;
                min-width: 20%;
                background-size: 100% 90%;
                background-repeat: no-repeat;
                background-position: center center;

                .pieDescDiv {
                    //  background-color: rgba(102, 51, 153, 0.345);
                    height: 80%;
                    width: 90%;
                    position: relative;
                    top: 10%;
                    padding: 2%;
                    left: 5%;

                    .male_p,
                    .female_p {
                        font-size: 20px;
                        position: absolute;
                        top: 40%;
                        left: 50%;
                        color: black;
                    }

                    .female_p {
                        top: 5%;
                        left: 12%;
                    }
                }
            }

            .the_bar {
                //             background-color: blue;
                min-width: 75%;
                background-size: 100% 100%;
            }
        }

        .slide2,
        .slide1,
        .slide3 {
            min-width: 100%;
            height: 40%;
            //   background-color: rgba(87, 65, 112, 0.603);
            z-index: 3;
            margin: -2% 0% 2%;
            display: flex;
            justify-content: space-evenly;
            align-items: flex-start;

            .imgUrl1,
            .imgUrl2,
            .imgUrl,
            .imgUrl3 {
                //    background: rgba(255, 0, 0, 0);
                min-width: 25%;
                min-height: 100%;
                background-repeat: no-repeat;
                background-position: center;
                position: relative;
                background-size: 100%;

                .pietext,
                .pietext_pink {
                    position: absolute;
                    left: 51%;
                    top: 37%;
                    font-size: 17px;
                    color: black;
                    margin: 0px;
                    font-weight: 400;
                }

                .pietext_pink {
                    left: 35%;
                    top: 20%;
                }
            }

            .imgUrl2 {
                background-repeat: no-repeat;
                background-position: center;
                background-size: 119vh 33vh;
                height: auto;
                min-width: 75%;

                .signupline_p1ID {
                    font-size: 32px;
                    font-weight: 600;
                    text-align: right;
                    width: 95%;
                    margin: 0.5%;
                    min-height: 35vh;
                    //      background: #ff00003b;
                }
            }

        }

        .slide1 {
            margin-top: 0px;
            background-color: (217, 217, 217, 0.25);
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .imgUrl {
                // background: rgba(0, 234, 255, 0.544);
                min-width: 100%;
                min-height: 95%;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 90%;
                position: relative;

            }
        }

        .slide3 {
            min-width: 100%;
            //    background-color: #4f1b87;
            margin: 0% 0% 2%;
            display: flex;
            justify-content: space-evenly;
            align-items: flex-start;

            .imgUrl3 {
                //    background: rgba(0, 234, 255, 0.544);
                min-width: 100%;
                min-height: 95%;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 90%;
                position: relative;

            }
        }

        .line_div {
            padding: 0% 1%;
            border-radius: 15px;
            height: 100%;
            background: rgba(217, 217, 217, 0.2509803922);
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            .line_canvas {
                //    background: rgba(102, 45, 102, 0.31);
                width: 100%;
                min-height: 50%;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .comp_name_p {
                    //  margin: 0;
                    font-size: 32px;
                    font-weight: 600;
                }

                .figure_display {
                    //     background-color: rgba(30, 123, 123, 0.6);
                    overflow: hidden;
                    height: 12vh;
                    display: flex;
                    width: 100%;
                    align-items: stretch;
                    position: relative;

                    .figure_aux {
                        position: absolute;
                        //background: yellow;
                        width: 100%;
                        top: -15vh;
                    }
                }

                .compLine {
                    background-color: #0B132F;
                    background-size: 100% 100%;
                    margin-top: 1%;
                    background-position: center;
                    min-height: 30vh;
                    background-repeat: no-repeat;
                    min-width: 100%;
                    border-radius: 15px;
                }
            }
        }

        .token_ptag {
            background-color: rgba(217, 217, 217, 0.25);
            border-radius: 15px;
            width: 95%;
            height: 45px;
            margin: 0;
            text-align: left;
            padding-left: 3%;
            display: flex;
            font-weight: 600;
            align-items: center;
            font-size: 20px;
        }
    }

    .comp_div_image {
        background-color: #0b132f00;
        min-height: 3500px;
        min-width: 100%;
        // background-image: url('./images/firstPage.png');
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
}