.adminComplianceUserItem{
    width: '90%';
    background-color: #0B132F;
    height: 80px;
    border-radius: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.AdminComplianceSelectedUserContainer{
    width: 78%;
    overflow: scroll;
    background: linear-gradient(60deg, rgba(13,20,45,1) 37%, rgba(17,44,66,1) 85%);
    position: absolute;
    top: 100px;
    display: flex;
    flex-direction: row;
    border: 1px solid #E52E80;
    border-radius: 15px;
    .AdminComplianceSelectedUserCompliance{
        width: 30%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        .complianceImageContrainer{
            width: 100%;
            height: 400px;
            border: 1px solid white;
            img{
                width: 100%;
                height: 400px;
            }
        }
    }
    .AdminComplianceSelectedUserInfo{
        width: 60%;
        padding: 10px;
        .AdminComplianceSelectedUserInfoMeta{
            height: 400px;
        }
        .AdminComplianceSelectedUserInfoTransactions{
            height: -moz-calc(100% - 400px);
            height: -webkit-calc(100% - 400px);
            height: -o-calc(100% - 400px);
            height: calc(100% - 400px);
            display: flex;
            flex-direction: column;
            align-items: center;

            .complianceTransaction{
                width: 95%;
                border: 1px solid white;
                border-radius: 15px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
}

.complianceMetadataContainer{
    width: 100%;
    p{
        text-align: left;
    }
}

.complianceSummaryButton{
    background-color: rgb(229, 46, 128);
    width: 250px;
    height: 60px;
    border: 1px solid rgb(229, 46, 128);
    border-radius: 30px;
    min-height: 48px;
    margin: 20px 5px 5px;
    color: rgb(255, 255, 255) !important;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
}