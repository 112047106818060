.listingFilter{
    width: 100vw;
    display: flex;
    flex-direction: row;
    padding: 20px;
    padding-bottom: 0px;
    // align-items: center;
    justify-content: space-between;
    overflow-x: scroll;
    white-space: nowrap; 

    .listingFilterItem{
        height: 30px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFF;
        margin-left: 5px;
        margin-right: 5px;
        p{
            margin-top: 0px;
            margin-bottom: 0px;
            font-size: 17px;
        }
    }

    .secondaryFilter{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 75px;
        min-width: 75px;
        margin-left: 15px;
        margin-right: 15px;
        p{
            margin: 0px;
            margin-top: 10px;
            // width: 100%;
            overflow: hidden;
            white-space: normal;
            word-wrap: break-word;
        }
        
    }
}

.slidingSearchInput {
    transition: transform 0.5s, opacity 0.5s, height 0.5s, visibility 0.5s;
    transform: translateY(0px);
  }
  
  .slidingSearchInputHidden {
    transform: translateY(-80px)!important;
    opacity: 0 !important;
    height: 0px !important;
    visibility: hidden !important;
    margin: 0px;
  }

  .slidingSearchInput.visible {
    transform: translateY(0);
    opacity: 1;
  }