.companiesDiv{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    /* transition-duration: 0.25s; */
    
}

.companiesDivDesktop{
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.showCompany{
    top: 60px !important;
    position: fixed;
    width: 100% !important;
    height: 100%;
    // transition: top .1s linear;
    // transition-duration: 0.75s;
    // transition-property: linear;
    // transition: max-height 0.4s linear;

    .companyCard{
      top: 60px;
      position: fixed;
      // background-color: green;
    }

    // .companyContent{
    //     display: flex;
    //     flex-direction: column;
    //     bottom: 0px !important;
    //     transition: bottom 0.5s;
    //     // z-index: 999;
    //     // align-items: center;
    // }
}

.hiddenCompany{
    visibility: hidden;
    .companyContent{
      // visibility: hidden !important;
    }
}

.ContentCompanyDiv{
  overflow: scroll;
  overflow-x: hidden;
}

.companyTabBar{
  // position: fixed;
  bottom: 0px;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  background-color: #E52E80;
  // background: linear-gradient(90deg, rgba(229,46,128,1) 3%, rgba(0,107,172,1) 79%);
  align-items: center;
  justify-content: space-evenly;
}

.companyTabBarItem img{
  height: 40px;
}

.companyTabBarItem{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.filterTabBar{
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  background-color: #44197C;
  // align-items: center;
  justify-content: space-around;
  align-items: center;
}
.companyCardDiv{
    width: 100vw;
    border: 0px;
    margin: 0px;
    // transition: position 5s;
    // transition: top 5s;
    // transition: height 5s;
}

.companyCardDivDesktop{
  width: 30vw;
  border: 0px;
  margin: 0px;
}

.companyCard{
    // overflow: hidden;
    // overflow-y: scroll;
    width: 100%;
    /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); */
    display: flex;
    flex-direction: column;
    padding: 0px;
    background-color: #0B132F;
    // background: linear-gradient(#000000, #0B132F);
    /* margin-bottom: 20px; */
    border-radius: 0px;
    margin: 0px;
    height: 100%;
}
.companyCardBanner{
    position: relative;
    display: inherit;
    width: 100%;
    height: 260px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    padding-top: 20px;

    img{
      height: 150px;
      width: 150px;
    }

}
.companyBannerTitle{
  width: 100%;
  height: 80px;
  background-color: #57006ad4;
  p{
    text-align: left;
  }

}

.companyBannerSubtitle{
  height: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  img{
    height: 150px;
    width: 150px;
  }
}
.companyBannerDynamic{
  background-color: #44197cb7;
  width: 60%;
  height: auto;
  margin-top: 10px;
}

.companyCardBanner img{
    // width: 100%;
    // /* height: 160px; */
    // /* border-top-left-radius: 10px; */
    // /* border-top-right-radius: 10px; */
    // object-fit: cover;
}

.companyCardLogo img{
    z-index: 1;
    width: 120px;
    height: 120px;
    position: relative;
    margin-top: -80px;
    display: block;
    margin-left: 0px;
    margin-right: auto;
    border-radius: 100px;
    /* border: 10px solid #fff; */
    filter: drop-shadow(2px 1px 5px black);
    transition-duration: 0.4s;
    transition-property: transform;
}


.companyCardMenu{
    width: 60px;
    height: 60px;
    // z-index: 101;
    position: absolute;
    top: 10px;
    right: 10px;
    /* position: relative;
    margin-right: auto;
    border-radius: 100px;
    filter: drop-shadow(2px 1px 5px black);
    transition-duration: 0.4s;
    transition-property: transform;
    margin-top: -63%;
    margin-left: 88%; */
    /* z-index: 101; */
    /* display: block; */
    
}

// .mn img{
//   position: relative;
//   left: 10px;
//   top: 5px;
// }
.mn{
    width: 40px;
    height: 40px;
    position: relative;
    margin-right: auto;
    border-radius: 100px;
    filter: drop-shadow(2px 1px 5px black);
    /* transition-duration: 0.4s;
    transition-property: transform; */
    /* margin-top: -63%;
    margin-left: 88%; */

    // &::before, &::after {
    //     content: "";
    //     background: #c3c2c7;
    //     border-radius: 5px;
    //     width: 30px;
    //     height: 5px;
    //     position: absolute;
    //     left: 15px;
    //     top: 25px;
    //     transition: 0.2s ease;
    //     z-index: 1;
    //   }
      img{
        position: relative;
        left: 10px;
        top: 5px;
        transition-duration: 0.4s;
        transition-property: transform;
      }
      &::before {
        transform: rotate(0deg);
      }
      &::after {
        transform: rotate(-90deg);
      }
    &.open {
        opacity: .9;
        &::before {
          transform: rotate(45deg);
        }
        &::after {
          transform: rotate(-45deg);
        }
        // background-color: #ff0000;
        img{
          transform: rotate(45deg);
          transition-duration: 0.4s;
          transition-property: transform;
        }
        .btn {
          opacity: 1 !important;
          pointer-events: auto;
          &:first-of-type {
            // bottom: 10px;
            // right: 27px;
            bottom: -55px;
            right: -20px;
            // background: url("https://bassets.github.io/cam.svg") no-repeat 50%/50% #e8e8f3;
          }
          &:nth-of-type(2) {
            bottom: -43px;
            right: 27px;
            // background: url("https://bassets.github.io/img.svg") no-repeat 50%/ 50% #e8e8f3;
            transition-delay: .05s;
          }
          &:last-of-type {
            bottom: 0px;
            right: 40px;
            // background: url("https://bassets.github.io/music.svg") no-repeat 50% 45%/50% 45% #e8e8f3;
            transition-delay: .1s;
          }
        }
      }
}
.companyBackArrow{
    top: 0px;
    left: 20px;
    position: absolute;
    height: 30px;
    width: 30px;
    visibility: hidden;
}

.companyContent{
  // display: none;
  color: black !important;
  position: absolute;
  border: 1px solid black;
  background-color: #0B132F;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  // transition: all 0.5s ease-in;
  transition: opacity 0.3s ease-in-out;
  

  top: 60px;
  // max-height: 869px;
  // min-height: 869px;
  width: 100%;
  z-index: 3000;
  visibility: hidden;
  opacity: 0;
}

.companyContentShow{
  // top: 60px !important;
  visibility: visible;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}
@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.btn {
    height: 37px;
    width: 37px;
    padding: 00px;
    border-radius: 50%;
    cursor: pointer;
    background: #e8e8f3;
    position: absolute;
    // bottom: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
    box-shadow: inherit;
    transition: 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28), 0.2s ease opacity, 0.2s cubic-bezier(.08,.82,.17,1) transform;
    display: flex;
    align-items: center;
    justify-content: center;

}

.companyCardContent p{
    color: black !important;
    text-align: justify;
    padding: 0 20px 5px 20px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.companyCardDynamic{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.companyCardButtons{
    display: flex;
    padding: 0 20px 20px 20px;
}

.filterSelect{
  margin-bottom: 1em;
  font-weight: bold;
  appearance: none;;
  color: white;
  background-color: #0B132F;
  border: none;
  font-size: 20px;
  width: 75px;
  margin-right: 5px;
}

.filterOptionsDiv{
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.filterDivider{
  width: 50%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.filterCards{
  width: 80px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterCards p{
  font-size: 12px;
}

.companyContent{
  .heading{
    text-align: left;
  }
  p{
    text-align: left;
  }
}

.companyCardHr{
  width: 20%;
  margin-top: 30px;
}

.companyContentSection{
  margin-top: 20px;
  margin-bottom: 20px;
}

.buyingInfoP{
  margin-top: 1px;
  margin-bottom: 1px;
  font-weight: bold;
}

.buyingInputs{
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.companySocialMedia img{
  width: 10%;

}


.companySocialIcons{
  width: 100%;
  margin-left: 20px;
}

.companyMenuListItems{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
}

.companyMenuListItem{
  margin: 0px;
}

.selectedCompanyMenuItem{
  color: #EC1D74;
  padding-bottom: 4px;
  /* text-decoration: underline;
  text-decoration-thickness: 4px; */
  border-bottom: 4px solid #EC1D74;

}

.companyCloseButton{
  width: 10%;
  position: absolute;
  right: 0px;
  z-index: 1000;
};

.thePitchHeading{
  font-size: 20px;
  font-weight: bold;
}

.highlightImages{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.highlightImages img{
  margin-right: 5px;
  margin-left: 5px;
}

.thePitchDivider{
  width: 10%;
  margin-top: 50px;
  margin-bottom: 50px;
}
.thePitchDividerDesktop{
  width: 80%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.psTitle{
  color: #FFFFFF;
  font-weight: bolder;
}

.teamMemberDiv{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
}

.theTeamContentDesktop{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.teamMemberDivDesktop{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;

}

.teamName{
  color: #FFFFFF;
  font-weight: bolder;
  margin-bottom: 2px;
}

.teamRole{
  font-weight: lighter;
  margin-top: 2px;
}

.tractionContent{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  p{
    text-align: center;
  }
}

.tractionContentDesktop{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  p{
    text-align: center;
  }
}

.tractionImageDiv{
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin-left: 10px;
  margin-right: 10px;
  align-items: center;
  // height: 200px;
}

.tractionImageDivDesktop{
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-left: 10px;
  margin-right: 10px;
  align-items: center;
  // height: 200px;
}

.trajectoryTitle{
  color: #FFFFFF;
  font-weight: bolder;
}

.filterDiv{
  position: absolute;
  background-color: #4f1b87;
  z-index: 999;
  border-radius: 10px;
  bottom: 80px;
}

#filterCategory{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.filterCategory{
  width: 100px;
  background-color: transparent;
  border: 1px solid white;
  p{
    margin: 0px;
  }
}

.filterCard{
  border: 1px solid white;
  background-color: transparent;
  p{
    margin: 0px;
  }
}

.amountDiv{
  border: 8px solid #404952;
  border-radius: 15px;
  // filter: drop-shadow(0px 0px 4px black);
  margin-right: 5px;

  p{
    color: #404952
  }
}

.stakeDiv{
  border: 8px solid #404952;
  border-radius: 15px;
  // filter: drop-shadow(0px 0px 4px black);
  margin-left: 5px;

  p{
    color: #404952
  }
}

.companyLogoImage{
  // width: 150px;
  width: 180px;
  margin-right: 10px;
  filter: drop-shadow(1px 1px 3px black);
}

.amountStakeDiv{
  p{
    color: white;
    font-weight: bold;
    margin-top: 2px;
  }
}

.newCompanyCard{
  display: flex;
  justify-content: space-between;
  background-size: cover;
  background-blend-mode: overlay;
  background-color: #908BC3;
  border-radius: 25px;
  height: 150px;
}

.compImgBut{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  justify-content: space-between;
}

.compButton{
  width: 150px !important;
  height: 30px !important;
  min-height: 0px !important;
  padding: 0px !important;
  border: none !important;
}

.investPasswordDiv{
  width: 90vw;
  height: 90vh;
  position: absolute;
  background-color: #0B132F;
  top: 5vh;
  left: 5vw;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.buyConfirmation{
  position: absolute;
  width: 100%;
  height: 95vh;
  top: 5vh;
  /* left: 2.5vw; */
  /* background-color: #0B132F; */
  border-radius: 25px;
  z-index: 4000;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  background: linear-gradient(60deg, rgba(13,20,45,1) 37%, rgba(17,44,66,1) 85%);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  
}
.buyConfHeader{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 5px;
  padding-left: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}
.buyConfLogo{
  height: 5vh;
}
.buyConfClose{
  width: 40px;
}
.buyConfTitle{
  font-size: 25px;
  font-weight: bold;
}
.buyConfTable{
  width: 100%;
  color: white;
  padding-right: 10px;
  padding-left: 10px;
}
.buyConfTableLeft{
  text-align: left;
}
.buyConfTableRight{
  text-align: right;
}
.buyConfConfirm{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-top: 50px;
  margin-top: 20px;

}

.buyConfPass{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.InvestDesktopContainer{
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.InvestDesktopNav{
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: flex-start;
}

.InvestDesktopContent{
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.InvestDesktopCompNavItem{
  border: 3px solid white;
  border-radius: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 30%;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 30px;
  transition: all 0.5s;


  img{
    width: 80%;
  }
}
.InvestDesktopCompNavItemSelected{
  width: 60%;
  aspect-ratio: unset;
  border-radius: 25px;
  transition: all 0.5s;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  img{
    width: 45%;
  }
}

.InvestDesktopSections{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.InvestDesktopSelectedCompany{
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.InvestDesktopSelectedCompanyCnt{
  width: 90%;
}
.companyTabBarItemDesktop img{
  // height: 40px;
}
.desktopFixedSidebar{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-items: flex-start;
    width: 20%;
    top: 15vh;
}
.InvestDesktopSelectedCompanyNav{
  border-left: 1px solid white;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  width: 90%;
  height: 50px;
}

.thePitchContentImgContent{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.companyTabBarItemDesktopSelected{
  width: 60% !important;
}

.investCompCard{
  background-color: #FFFFFF;
  width: 90%;
  margin-left: 5%;
  border-radius: 15px;
  padding-top: 10px;
  padding: 3px;
  p{
    color: #4f1b87;
  }
  .compSubtitle{
    margin: 0px;
  }
  .compTitle{
    margin: 0px;
    font-weight: bolder;
    font-size-adjust: 0.8;
    font-size: 45px;
    // line-height: 40px;
    
  }
  .compSmall{
    font-size: 12px;
  }
}

.investCompCardDesktop{
  width: 70%;
  background-color: #FFFFFF;
  border-radius: 15px;
  padding: 15px;
  p{
    color: #4f1b87;
  }
  .compSubtitle{
    margin: 0px;
    font-size: 20px;
  }
  .compTitle{
    margin: 0px;
    font-weight: bolder;
    font-size-adjust: 0.8;
    font-size: 60px;
    // line-height: 45px;
  }
  .investCompCardDesktopFlex{
    width: 100%;
    display: flex;
  }
  .compSmall{
    font-size: 13px;
  }
}

.companyPopupBanner{
  width: 100%;
  height: 15%;
  background-size: cover;
  background-blend-mode: multiply;
  background-color: #00000076;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.companyPopupBannerDesktop{
  width: 100%;
  height: 15vh;
  background-size: cover;
  background-blend-mode: multiply;
  background-color: #00000076;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buyGraphContainer{
  background-color: #0B132F;
  width: 90%;
  height: 40%;
  border-radius: 15px;
  padding: 10px;
}
.buyGraphContainerDesktop{
  height: 70%;
  width: 85%;
  background-color: #0B132F;
  border-radius: 15px;
  padding: 10px;
}
.buyInputContainer{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.buyAmountInput{
  border-left: none;
  border-right: none;
  border-top: none;
  border-color: #32C2D5;
  width: 30%;
  border-width: 3px;
  font-size: 25px;
  font-weight: bold;
  outline: none;
}

// .buyAmountSlider{
//   // -webkit-appearance: none;
//   background-color: #9a905d;
// }
input[type="range"] {
  accent-color: #32C2D5;
}

.buyAddAmountButton{
  width: 22%;
  height: 40px;
  color: white;
  background-color: #E52E80;
  outline: none;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  font-size: 20px;
}

.buyAddAmountButtonBlue{
  width: 80%;
  height: 40px;
  color: white;
  background-color: #32C2D5;
  outline: none;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  font-size: 20px;
  margin-top: 20px;
}

.verificationPopup{
  position: absolute;
  width: 100%;
  height: 95vh;
  top: 5vh;
  /* left: 2.5vw; */
  /* background-color: #0B132F; */
  border-radius: 25px;
  z-index: 4000;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  background: linear-gradient(60deg, rgba(13,20,45,1) 37%, rgba(17,44,66,1) 85%);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
}
