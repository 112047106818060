@import '../../var.css';

::placeholder{
    color: #A39F9F;
    font-size: 16px;
}

input{
    /* background-color: #FFFCFC; */
    /* background-color: transparent; */
    background-color: transparent;
    /* border: 0.5px solid black; */
    border: 2px solid white;
    font-size: 16px;
    text-align: center;
    /* box-shadow: inset 0 4px 2px -2px gray; */
    /* border-radius: 10px; */
    color: white;
    margin: 5px;
    margin-bottom: 20px;
    width: 80%;
    height: 45px;
    min-height: 45px;
    /* margin-left: 40px; */


}

.inputLabel{
    text-align: left;
    
}

.inputDiv{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    /* display: table-cell;s */
}

.disabled{
    filter: brightness(70%);
}

.inputContainer{
    background: white;
    display: flex;
    height: 50px;
    width: 80%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-bottom: 10px;
}

.inputImage{
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: black; */
    margin-top: 8px;
    margin-left: 2px;

}


.inputInput{
    background: #0B132F;
    height: 46px;
    /* margin: 0px !important; */
    width: 100%;
    margin: 2px;
    padding: 0px;
    border: 0px;
    border-radius: 0px;
    text-align: left;
    padding-left: 10px;
    outline: none;
    font-size: 20px;
}

.inputP{
    text-align: left;
    width: 80%;
    margin-top: 0px;
    margin-bottom: 2px;
    text-transform: capitalize;
}