.adminNavContainer{
    width: 20%;
    background-color: #0B132F;
    height: 100%;
    padding: 10px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .adminNavItem{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        margin-top: 10px;
        margin-bottom: 10px;
        width: 90%;
        height: 60px;
        border-radius: 15px;
        border: 1px solid white;
        cursor: pointer;
        p{
            margin: 0px;
            padding-left: 5px;
        }
    }
    .adminNavItemActive{
        background: linear-gradient(90deg, rgba(229,46,128,1) 3%, rgba(0,107,172,1) 79%) !important;
    }
}

.adminContentContainer{
    width: 80%;
    height: 100%;
    padding: 10px;
}