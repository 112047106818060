.signups {
    height: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2% 0 1% 0;

    .signup_p {
        font-size: 40px;
        font-weight: 900;
        line-height: 52.73px;
        text-align: left;
        margin:0 0 0 2%;
        width: 98%;
        display: flex;
    }

    .graphs_div {
        height: 96%;
        width: 99%;
        border-radius: 15px;
        align-items: center;
        display: flex;
        flex-direction: column;

        .chart_div {
            height: 50%;
            width: 100%;
            padding: 0.5% 0%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }

        .bar_div {
            background: rgba(255, 255, 255, 0.25);
            height: 97%;
            width: 100%;
            border-radius: 15px;
            margin-top: 0.5%;

            .bar_p {
                font-size: 30px;
                font-weight: 900;
                line-height: 35.16px;
                text-align: center;
                height: 4%;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                width: 95%;
            }

            .carousel-root,
            .carousel-root_inverse {
                height: 98%;
                border-radius: 15px;


                .carousel-slider {
                    position: relative;
                    margin: 0;
                    overflow: hidden;
                    height: 100%;

                    .control-dots {
                        visibility: hidden;
                    }

                    .slider-wrapper {
                        overflow: hidden;
                        margin: 0;
                        width: 100%;
                        transition: height .15s ease-in;
                        // background: black;
                        height: 100%;

                        .slider {
                            display: flex;
                            // background: gray;
                            height: 100%;

                            .slide {
                                min-width: 100%;
                                margin: 0;
                                position: relative;
                                text-align: center;
                                // background-color: #2a6091;
                                display: flex;
                                align-items: center;

                                .svgline_div {
                                    min-height: 100% !important;
                                    width: 98%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin-top: 0%;
                                    padding-bottom: 0%;
                                }

                                .svgchart_div {
                                    display: flex;
                                    justify-content: space-around;
                                    align-items: center;
                                    height: 100%;
                                    width: 100%;
                                }
                            }
                        }
                    }

                    .control-arrow {
                        top: 2.5%;
                        right: 0.5%;
                        color: #fff;
                        font-size: 11px;
                        bottom: 0;
                        height: 10%;
                        width: 1.5%;
                        position: absolute;
                        margin-top: 0;
                        padding: 6px;
                        background-repeat: no-repeat;
                        background-size: 65%;
                        background-position: 35% 5%;
                        background-image: url('./images/rightArrow.svg');


                        &:before {
                            margin: 0 5px;
                            display: inline-block;
                            border: unset;
                            border: unset;
                            content: '';
                        }

                        &:hover {

                            &:hover {
                                background-color: transparent;
                            }
                        }


                    }
                }
            }

            .carousel-root_inverse {
                .carousel-slider {
                    .slider-wrapper {
                        .slider {
                            .slide {
                                min-width: 100%;
                                margin: 0;
                                position: relative;
                                text-align: center;
                                display: flex;
                                flex-direction: row;
                                justify-content: space-around;
                            }
                        }
                    }

                    .control-arrow {
                        left: 0.5%;
                        background-image: url('./images/leftArrow.svg') !important;
                    }
                }
            }


        }

        .token_div {
            display: flex;
            width: 100%;
            height: 8%;
            align-items: flex-end;

            .p_token {
                font-size: 25px;
                font-weight: 400;
                line-height: 29.3px;
                text-align: left;
                width: 90%;
                background: rgba(217, 217, 217, 0.25);
                border-radius: 10px;
                padding-left: 10px;
                height: 84%;
                display: flex;
                align-items: center;
                margin: 1% 4% 0 0%;

                span {
                    font-size: 25px;
                    font-weight: 700;
                    line-height: 29.3px;
                    text-align: left;
                }
            }

            .imgtoken {
                background: rgba(255, 255, 255, 0.25);
                height: 80%;
                border-radius: 10px;
                aspect-ratio: 1/1;
                margin-top: 1%
            }
        }
    }
}