.selectInputContainer{
    width: 90%;
    min-height: 60px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.25);
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
    p{
        height: 20%;
        margin: 0px;
        font-size: 10px;
        font-weight: 700;
        text-align: left;
    }
    select{
        height: 100%;
        top: 0px;
        left: 0px;
        border-radius: 15px;
        margin: 0px;
        padding: 0px;
        border: 0px;
        width: 100%;
        text-align: left;
        font-size: 16px;
        color: transparent;
        outline: none;
        opacity: 0.5;
        // display: none;
        appearance: none;
        position: absolute;
        background-color: transparent;
        z-index: 5;
    }
    input::placeholder{
        color: #FFF;
        opacity: 0.5;
        font-size: 16px;
    }
    .selectValue{
        text-align: left;
        font-size: 16px;
        min-height: 75%;
        font-weight: 400;
        opacity: 0.5;
    }
}