.reconHeader{
    color: #FFF;
    font-family: Roboto;
    font-size: 35px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin: 0px;
    margin-top: 15px;
    margin-bottom: 15px;
}
.moneyInContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    .moneyInItem{
        width: 20%;
        height: 120px;
        border-radius: 15px;
        background-color: #FFFFFF40;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .moneyInHeader{
            margin: 0px;
            margin-bottom: 5px;
            color: #FFF;
            text-align: center;
            font-family: Roboto;
            font-size: 25px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }
        .moneyInAmount{
            margin: 0px;
            color: #FFF;
            text-align: center;
            font-family: Roboto;
            font-size: 30px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
        }
    }
    .moneyInItemActive{
        border: 1px solid white;
    }
}
.ReconTable{
    width: 95%;
    background-color: #FFFFFF40;
    border-radius: 15px;
    padding: 10px;

    .ReconTableHeader{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content:center;

        p{
            margin: 0px;
            color: #FFF;
            font-family: Roboto;
            font-size: 30px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            text-align: left;
        }

        .reconCustomSelect{
            position: relative;
            p{
                margin: 0px;
                color: #FFF;
                font-family: Roboto;
                font-size: 30px;
                font-style: normal;
                font-weight: 900;
                line-height: normal;
                text-align: left;
                cursor: pointer;
            }
            .reconCustomSelectSelector{
                position: absolute;
                background-color: #0B132F;
                border-radius: 15px;
                .pSelection{
                    margin: 10px;
                    color: #FFF;
                    font-family: Roboto;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    text-align: left; 
                    cursor: pointer;
                }
                .pSelectionSelected{
                    color: #CE307A !important;
                }
            }
        }
    }
    .ReconTableRow{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content:center;
        margin-top: 10px;
        p{
            margin: 0px;
            color: #FFF;
            text-align: center;
            font-family: Roboto;
            font-size: 25px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            text-align: left;
        }
    }
    .ReconTableHeaderDivider{
        height: 3px;
        width: 100%;
        background-color: #FFFFFF40;
        margin-top: 10px;
    }
    .ReconTableRowDivider{
        height: 1px;
        width: 100%;
        background-color: #FFFFFF40;
        margin-top: 10px;
    }
}

.AllTransactionsHeader{
    width: 100%;
    display: flex; 
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    p{
        margin: 0px;
        color: #FFF;
        font-family: Roboto;
        font-size: 35px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        margin: 0px;
        margin-top: 15px;
        margin-bottom: 15px;
        margin-left: 2%;
    }
}


.ozowDetailsRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    .ozowDetailCol{
        width: 49%;
        .ozowDetailColHeader{
            margin: 0px;
            color: #FFF;
            font-family: Roboto;
            font-size: 30px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            margin: 0px;
            margin-top: 15px;
            margin-bottom: 15px;
        }
    }
}
