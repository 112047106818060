

.amountDiv{
    border: 8px solid #404952;
    border-radius: 15px;
    // filter: drop-shadow(0px 0px 4px black);
    margin-right: 5px;
  
    p{
      color: #404952
    }
  }
  
  .stakeDiv{
    border: 8px solid #404952;
    border-radius: 15px;
    // filter: drop-shadow(0px 0px 4px black);
    margin-left: 5px;
  
    p{
      color: #404952
    }
  }
  
  .companyLogoImage{
    // width: 150px;
    width: 180px;
    margin-right: 10px;
    margin-top: 10px;
    filter: drop-shadow(1px 1px 3px black);
  }
  
  .amountStakeDiv{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    p{
      color: white;
      font-weight: bold;
      margin-top: 10px;
      margin-left: 5px;
      margin-right: 0px;
    }
  }
  
  .CompanyCard{
    display: flex;
    justify-content: space-between;
    background-size: cover;
    background-blend-mode: overlay;
    // background-color: #908BC3;
    border-radius: 25px;
    height: 150px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 15px;
    margin-right: 15px;
  }
  
  .compImgBut{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
    justify-content: space-between;
  }
  
  .compButton{
    width: 150px !important;
    height: 30px !important;
    min-height: 0px !important;
    padding: 0px !important;
    border: none !important;
    margin-right: 10px !important;
    // background: linear-gradient(90deg, rgba(229,46,128,1) 3%, rgba(0,107,172,1) 79%);

  }

  .remainingP{
    font-size: 29px;
  }

.descP{
  text-align: left;
  margin-left: 5px;
}