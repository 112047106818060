.psTextArea{
    width: 90%;
    min-height: 150px;
    resize: none;
    background-color: #FFFFFF40;
    border: none;
    outline: none;
    color: #FFF;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding: 0px;
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 20px;
}
.psTextArea::placeholder{
    color: #FFFFFF80;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
.HTAdminContainer{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 20px;
    border-radius: 15px;
    background-color: #FFFFFF40;
    padding: 20px;
    

    .hlHeader{
        margin: 0px;
        color: #FFF;
        font-family: Roboto;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: 100%;
        text-align: left;
    }

    .hlInputsContainer{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;

        .hlTextArea{
            width: 90%;
            height: 150px;
            resize: none;
            background-color: #FFFFFF40;
            border: none;
            outline: none;
            color: #FFF;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            padding: 0px;
            border-radius: 15px;
            padding: 20px;
        }
        .hlTextArea::placeholder{
            color: #FFFFFF80;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
        }

        .uploadIconContainer{
            height: 60px;
            width: 60px;
            background-color: #FFFFFF40;
            aspect-ratio: 1/1;
            border-radius: 15px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }

    
}

.bottomButtonContainer{
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}