.DesktopPortfolioAmounts{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    .portfolioAmountCard{
        width: 30%;
        height: 98%;
        background-color: #FFFFFF40;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .headerText{
            color: #FFF;
            text-align: center;
            font-family: Roboto;
            font-size: 25px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            margin: 0px;
        }
        .amountText{
            color: #FFF;
            text-align: center;
            font-family: Roboto;
            font-size: 30px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            margin: 0px;
        }
        .subText{
            color: #47FF00;
            text-align: center;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0px;
        }
    }
    .totalValue{
        background-color: #E9E9E9;
        .headerText{
            color: #0B132F;
            text-align: center;
            font-family: Roboto;
            font-size: 25px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            margin: 0px;
        }
        .amountText{
            color: #0B132F;
            text-align: center;
            font-family: Roboto;
            font-size: 30px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            margin: 0px;
        }
        .subText{
            color: #E43180;
            text-align: center;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0px;
        }
    }
}