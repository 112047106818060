.clientFileComplianceChecklist{
    height: calc(100% - 75px) !important;
    .complianceHeader{
        color: #FFF;
        font-family: Roboto;
        font-size: 45px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        text-align: left;
        width: 88%;
    }

    .complianceHeaderFieldContainers{
        width: 90%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .complianceChecklistTableContainer{
        width: 90%;
        background-color: #FFFFFF40;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 15px;
        margin-top: 20px;
        margin-bottom: 50px;
        .complianceChecklistTableHeader{
            display: flex;
            flex-direction: row;
            width: 100%;
            p{
                color: #FFF;
                font-family: Roboto;
                font-size: 30px;
                font-style: normal;
                font-weight: 900;
                line-height: normal;
                margin: 0px;
            }
        }
        .complianceChecklistTableHeaderDivider{
            width: 100%;
            height: 3px;
            background-color: #FFFFFF40;
            margin-top: 10px;
            border-radius: 15px;
        }
        .complianceChecklistTableRow{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            margin-top: 10px;
            .questionHeader{
                color: #FFF;
                font-family: Roboto;
                font-size: 25px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                margin: 0px;
            }
            .complianceChecklistTableCheckbox{
                width: 10%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                img{
                    cursor: pointer;
                }
            }
        }
        .complianceChecklistTableRowDivider{
            width: 100%;
            height: 1px;
            border-radius: 15px; 
            background-color: #FFFFFF40;
            margin-top: 10px;
        }
    }

    .ficaHeader{
        text-align: left;
        color: #FFF;
        font-family: Roboto;
        font-size: 45px;
        font-style: normal;
        font-weight: 900;
        line-height: normal;
        width: 90%;
        margin: 0px;
    }

    .additionalCommentsHeader{
        width: 90%;
        min-height: 354px;
        aspect-ratio: 3.4350282486/1;
        background-color: #FFFFFF40;
        border-radius: 15px;
        margin-top: 20px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 15px;

        .commentsHeader{
            margin: 0px;
            width: 100%;
            color: #FFF;
            font-family: Roboto;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-align: left;
            height: 8%;
        }

        .commentsTextArea{
            width: 100%;
            height: 92%;
            resize: none;
            overflow: scroll;
            background-color: transparent;
            border: none;
            outline: none;
            text-align: left;
            font-size: 16px;
            color: #FFF;
            outline: none;
            opacity: 0.5;
        }
        .commentsTextArea::placeholder{
            color: #FFF;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            opacity: 0.5;
        }
    }
}