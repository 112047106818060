.updateAdminCointainer{
    width: 80%;
    margin-bottom: 30px;
    background-color: #FFFFFF40;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    position: relative;
    .updateAdminCoin{
        position: absolute;
        top: 0px;
    }

    .updateAdminDetails{
        width: 60%;
        height: 100%;
        .updateAdminCompany{
            color: #FFF;
            font-family: Roboto;
            font-size: 30px;
            font-style: normal;
            font-weight: 900;
            line-height: normal;
            margin: 0px;
            text-align: left;
            margin-bottom: 5px;
        }
        .updateAdminTitle{
            color: #FFF;
            font-family: Roboto;
            font-size: 20px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            margin: 0px;
            text-align: left;
            margin-bottom: 5px;
        }
    }
    .updateAdminPreviewCont{
        width: 40%;
    }
}