.button{
    min-width: 50%;
    min-height: 50px;
    border-radius: 15px;
    box-shadow: none;
    border: 0px;
    font-size: 20px;
    font-weight: 700;
    color: #FFF;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}