.IOSInstallPrompt{
    background-color: #CE307A;
    position: absolute;
    bottom: 0px;
    padding-right: 5px;
    width: 100%;
    z-index: 5;
}

.iosPromptDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.iosPromptImg{
    height: 60px;
    padding-left: 10px;
}

.iosPromptHeader{
    font-weight: bold;
    font-size: 15px;
}

.iosPromptSub{
    font-size: 15px;
}

.closeIosPrompt{
    color: white;
    padding-right: 10px;
    font-size: 25px;
}