.adminOverviewContainer{
    width: 100%;
    height: 100%;
    overflow: scroll;
}
.adminOverviewTransactionGraphContainer{
    height: 200px;
    padding: 10px;
    background-color: #0B132F;
    border-radius: 15px;
}

.totalTransactionsCard{
    background-color: #0B132F;
    width: 15%;
    height: 18vh;
    border-radius: 15px;
    border: 1px solid #E52E80;
    margin-top: 10px;
    margin-bottom: 10px;
}