.failedbar {
  height: 100%;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .name {
    margin: 0px;
    font-size: 30px;
    font-weight: 900;
    line-height: 35.16px;
    text-align: center;
  }

  .failedbar_popup {
    position: absolute;
    //background: #b40f0f40;
    border-radius: 15px 15px 15px 0px;
    cursor: pointer;
    color: white;
    min-height: 9px;
    min-width: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}