.entityDetailTopBar{
    width: 100%;
    border-top-left-radius: 100px;
    height: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .entityDetailHeader{
        color: #FFF;
        font-size: 35px;
        font-weight: 900;
        line-height: normal;
        text-align: left;
        margin-left: 7%;
        margin-bottom: 1%;
        margin-top: 2%;
    }
    .entityDetailTopBarMenu{
        width: 95%;
        height: 35%;
        background-color: #D9D9D940;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: 15px;
        // padding-left: 2%;
        // padding-right: 2%;
        justify-content: space-evenly;

        .entityDetailTopBarMenuItem{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 90%;
            // padding-left: 13px;
            // padding-right: 13px;
            padding-left: 2%;
            padding-right: 2%;
            border-radius: 15px;
            cursor: pointer;
            p{
                margin: 0px;
                color: #FFF;
                text-align: center;
                font-size: 30px;
                font-weight: 900;
                line-height: normal;
            }
            
        }
        .entityDetailTopBarMenuItemActive{
            background-color: #FFFFFF40;
        }
    }
}
