.listingPS{
    transition: width 0.2s, height 0.2s, position 0.7s, background-color 0.7s;
    width: 90%;
    height: 160px;
    background-color: white;
    border-radius: 15px;
    // margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    .psSvg{
        height: 75%;
    }
    .psHeader{
        margin: 0px;
        color: #12153D;
        text-align: center;
        font-size: 20px;
        font-weight: 900;
        // margin-left: 5px;
        // margin-right: 5px;
        line-height: normal;
    }
}

.listingPSActive{
    width: 100vw;
    height: calc(100vh - 50px);
    position: fixed;
    top: 50px;
    left: 0px;
    background-color: #0B132F;
    justify-content: flex-start;
    padding: 0px !important;
    z-index: 2;

    .psSelectedHeader{
        width: 100%;
        height: 20%;
        margin-top: 2%;
        border-top-left-radius: 100px;
        background: linear-gradient(79deg, #006BAC 19.17%, #E52E80 82.6%);
        margin-bottom: -3%;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 50%;
            object-fit: cover;
        }
    }
    .psSelectedBody{
        width: 100%;
        height: 83%;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        background: linear-gradient(60deg, rgba(13,20,45,1) 37%, rgba(17,44,66,1) 85%);
        // padding: 10px;
        // display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        overflow-y: scroll;
        overflow-x: hidden;
        .highlightsPSHeader{
            color: #FFF;
            font-size: 20px;
            font-weight: 900;
            margin: 0px;
            text-align: left;
            width: 100%;
            margin-bottom: 10px;
            margin-top: 10px;
            margin-left: 10px;
        }

        .psSummary{
            color: #FFF;
            font-size: 15px;
            font-weight: 300;
            text-align: left;
            margin-top: 0px;
            margin-bottom: 0px;
            line-height: normal;
            margin-bottom: 10px;
        }
        .psCardsContainer::-webkit-scrollbar{
            display: none;
        }
        .psCardsContainer{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: stretch;
            overflow-x: scroll;
            margin-bottom: 20px;
            margin-top: 10px;
            .psCardContainer{
                width: 85%;
                min-width: 85%;
                margin-left: 15px;
                margin-right: 15px;
                display: flex;
                flex-direction: column;
                -ms-overflow-style: none; 
                scrollbar-width: none;

                .psCardTitle{
                    margin: 0px;
                    color: #FFF;
                    font-size: 20px;
                    font-weight: 900;
                    text-align: left;
                    margin-bottom: 10px;
                }
                .psCard{
                    width: 100%;
                    background-color: #FFF;
                    border-radius: 15px;
                    border-top-left-radius: 0px;
                    flex: 1 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    .psCardImg{
                        width: 100%;
                        border-radius: 15px;
                        border-top-left-radius: 0px;
                        flex: 1 1;
                        object-fit: cover;
                    }
                    .psCardContent{
                        margin: 10px;
                        color: #0B132F;
                        text-align: center;
                        font-size: 15px;
                        font-weight: 300;
                    }
                }
            }
        }
    }
}