.figureDisplayParent {
    position: relative;
    height: 95%;
    width: 18%;
    // margin: 0.5%;
    // margin-top: 0%;
    display: flex;

    .figureDisplay {
        background: rgba(217, 217, 217, 0.25);
        height: 75%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            font-size: 30px;
            font-weight: 900;
            line-height: 35.16px;
            text-align: center;
            color: white;
            margin-top: -5%;
        }
    }

    .label {
        position: absolute;
        border-radius: 0px 15px 15px;
        background: white;
        color: black;
        top: 36%;
        width: 70%;
        margin-left: 0px;
        height: 45%;
        display: flex;
        font-size: 16px;
        font-weight: 900;
        justify-content: flex-start;
        align-items: center;
        overflow: auto;
    }
}

.figureDisplay2 {
    position: relative;
    height: 98%;
    width: 18%;
    // margin: 0.5%;
    // margin-top: 0%;
    display: flex;

    .figureDisplay {
        background: rgba(217, 217, 217, 0.25);
        height: 50%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 4%;
        border-radius: 15px;
        font-size: 80%;

        span {
            font-size: 30px;
            font-weight: 900;
            line-height: 35.16px;
            text-align: center;
            color: white;
            margin-top: 0%;
        }
    }

    .label {
        position: absolute;
        border-radius: 0px 15px 15px;
        top: 47%;
        width: 70%;
        margin-left: 0px;
        height: 35%;
        display: flex;
        font-size: 14px;
        font-weight: 900;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 8%;
        overflow: auto;
        color: white;
        background: none;
        span {
            width: 100% !important;
        }
    }
}