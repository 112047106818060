.contractHeader{
    width: 100%;
    height: 15%;
    padding: 20px;
    // background-color: red;
    p{
        margin: 0px;
        color: #FFF;
        text-align: center;
        font-size: 15px;
        font-weight: 300;
    }
    
    .contractScroller{
        width: 100%;
        height: 5px;
        background-color: #FFFFFF40;
        margin-top: 15px;
        margin-bottom: 15px;
        position: relative;
        border-radius: 15px;

        .sliderIcon{
            position: absolute;
            bottom: -150%;
            z-index: 6;
        }
        .progressBar{
            position: absolute;
            // bottom: -150%;
            left: 0px;
            height: 5px;
            background: linear-gradient(260deg, #E52E80 15.85%, #006BAC 85.56%);
            // width: 50%;
            z-index: 5;
            border-radius: 15px;
        }
    }
    .contractHeaderText{
        margin: 0px;
        color: #FFF;
        font-size: 15px;
        font-weight: 900;
        text-align: left;
    }
}

.contractContent{
    // background-color: blue;
    padding: 20px;
    padding-top: 0px;
    width: 100%;
    max-height: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
}

.slidingOtherInput{
    transition: transform 0.5s, opacity 0.5s, height 0.5s, visibility 0.5s;
    transform: translateY(0px);
}

.slidingOtherInputHidden{
    transform: translateY(-80px)!important; /* Hidden position */
    opacity: 0 !important;
    height: 0px !important;
    visibility: hidden !important;
}

.slidingOtherInput.visible {
    transform: translateY(0);
    opacity: 1;
}

.contract{
    width: 100%;
    p, ul, li, ol{
        color: #FFF;
        text-align: left;
        font-size: 15px;
        font-weight: 300;
        margin: 0px;
    }
    .heading{
        font-weight: 700;
    }
    .table{
        color: red;
    }
    .underline{
        text-decoration: underline;
    }
    .indent{
        margin-left: 5px;
    }

    table{
        color: white;
        border-collapse: collapse;
        width: 100%;
    }
    table, th, td {
        border: 1px solid #FFF;
    }
}

.scrollContainer{
    width: 100%;
    height: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // background-color: green;

    p{
        margin: 0px;
        color: #FFF;
        text-align: center;
        font-size: 15px;
        font-weight: 300;
    }
}
.signatureHeader{
    color: #FFF;
    font-size: 12px;
    font-weight: 700;
    text-align: left;
    align-self: flex-start;
}

.signatureClear{
    color: #E43180;
    text-align: right;
    font-size: 12px;
    font-weight: 700;
    align-self: flex-end;
}