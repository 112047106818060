.portfolioCard{
    border: 1px solid white;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-items: center;
    justify-content: space-between;
}

.portfolioImage{
    height: 85px;
}

.portfolioInfo{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.portfolioInfo p{
    margin-top: 10px;
    margin-bottom: 0px;
    margin-right: 0px;
    
}

.donutchart-innertext-value, .donutchart-innertext-label{
    fill: white;
}

.dashboardMenuItems{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
}

.selectedDashboardMenuItem{
    color: #EC1D74;
    padding-bottom: 4px;
    /* text-decoration: underline;
    text-decoration-thickness: 4px; */
    border-bottom: 4px solid #EC1D74;

}

.dashboardTabBar{
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    background-color: #44197C;
    align-items: center;
    justify-content: space-around;
  }
  
  .dashboardTabBarItem img{
    height: 40px;
  }

  .DesktopDashContainer{
    display: flex;
    flex-direction: row;
    height: 100%;
    margin-top: 20px;
  }

  .DektopDashNav{
    width: 20vw;
    /* border-right: 1px solid white; */
    height: 100%;
    margin-left: 20px;
  }

  .DesktopDashContent{
    width: 70vw;
    margin-left: 10vw;
    overflow: scroll;
    display: flex;
    justify-content: center;
    
  }
.DesktopDashNavCard{
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    background-color: #52319387;
    cursor: pointer;
}

.DesktopDashNavCardSelected{
    width: 102%;
    height: 85px;

}

.DesktopDashNavCard img{
    height: 90%;
    width: 62px;
}

.investBtn{
    position: absolute;
    top: 21%;
    left: 5%;
}

.profileReferComp{
    background-color: #FFFFFF;
  width: 90%;
  margin-left: 5%;
  border-radius: 15px;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
//   padding: 5px;

  margin-right: 5%;
  p{
    color: #4f1b87;
  }
  .compSubtitle{
    margin: 0px;
  }
  .compTitle{
    margin: 0px;
    font-weight: bolder;
    font-size-adjust: 0.8;
    font-size: 45px;
    line-height: 40px;
    
  }
  .compSmall{
    font-size: 12px;
  }

  .profileReferDetails{
    display: flex;
    justify-content: space-between;
    p{
        margin: 0px;
    }
    .referralCodeCont{
        background-color: #D1427E;
        border-radius: 12px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
            color: white;
            margin: 0px;
            text-align: center;
            font-weight: bold;
            font-size: 20px;
        }
    }
  }
}