@import '../../var.css';

#SidebarButton{
    width: 100%;
    height: 30px;
    background-color: #001F6D;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
}