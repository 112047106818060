.textInputDarkContainer{
    width: 90%;
    height: 60px;
    border-radius: 15px;
    background-color: #0000001A;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    p{
        height: 20%;
        margin: 0px;
        font-size: 10px;
        font-weight: 700;
        text-align: left;
        color: #0B132F;
        margin-top: 3px;
    }
    input{
        height: 70%;
        margin: 0px;
        padding: 0px;
        border: 0px;
        width: 100%;
        text-align: left;
        font-size: 16px;
        color: #0B132F;
        outline: none;
        opacity: 0.5;
    }
    input::placeholder{
        color: #0B132F;
        opacity: 0.5;
        font-size: 16px;
    }
}