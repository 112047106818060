.transDiv{
    border: 1px solid white;
    width: 80%;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-top: 5px;

    p {
        margin-top: 2px;
        margin-bottom: 2px;
    }
}

.transComp{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.updateTransactionDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    *{
        cursor: pointer;
    }
}

.transactionSearch{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.trnFilter{
    border: 1px solid white;
    border-radius: 15px;
    width: 100px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
}

.trnFilterActive{
    border: 1px solid #E2357E !important;

}