.addDependantsContainer{
    display: flex;
    flex-direction: column;
    align-items: center;

    .headerDependants{
        color: #0B132F;
        font-family: Roboto;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        text-align: left;
        width: 100%;
        margin: 0px;
    }
    .errorText{
        color: #D42E79;
        text-align: center;
        font-family: Roboto;
        font-size: 13px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
}