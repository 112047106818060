.adminTransactionSummary{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .adminSummaryContainer{
        width: 20%;
        .adminSummaryCard{
            background-color: #0B132F;
            width: 100%;
            border-radius: 15px;
            border: 1px solid #E52E80;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
}


