.rain-container {
  /* position: relative; */
  overflow: hidden;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
  z-index: 9000;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;

}

.raindrop {
  position: absolute;
  width: 50px;
  height: 50px;
  top: -20px; /* Adjust initial position above the screen */
  animation: fallAnimation linear infinite;
}

@keyframes fallAnimation {
  to {
    transform: translateY(110vh);
  }
}