.portfolioGrowthCard{
  transition: width 0.2s, height 0.2s, position 0.7s, background-color 0.7s;
  width: 100%;
  height: 100px;
  background-color: #0000001A !important;
  border: none !important;
  display: flex;
  flex-direction: row;
  padding-top: 15px;
  position: relative;
  margin-bottom: 50px;

  .portfolioHeader{
    margin: 0px;
    color: #0B132F;
    text-align: center;
    font-size: 13px;
    font-weight: 300;
  }
  .portfolioContent{
    margin: 0px;
    color: #0B132F;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
  }
  .currentValueContainer{
    width: 50%;
    height: 100%;
  }
  .profitContainer{
    width: 100%;
    height: 100%;
    .portfolioProfit{
      margin: 0px;
      color: #6FDB45;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
    }
  }

  .portfolioBanner{
    position: absolute;
    width: 70%;
    height: 60px;
    background-color: #0B132F;
    border-radius: 0px 15px 15px 15px;
    bottom: -30px;
    left: 0px;
    padding-left: 15px;

    .portfolioBannerHeader{
      margin: 0px;
      color: #FFF;
      font-size: 20px;
      font-weight: 900;
      text-align: left;
      margin-top: 3px;
    }
    .portfolioBannerCategory{
      margin: 0px;
      color: #FFF;
      font-size: 15px;
      font-weight: 300;
      text-align: left;
    }
  }
}

