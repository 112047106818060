.AEXNewListingContainer{
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #0B132F;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .newListingHeader{
        width: 100%;
        height: 75px;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;

        img{
            height: 99%;
        }
    }
    .newListingContent{
        width: 100%;
        height: calc(100% - 75px);
        background: linear-gradient(28deg, #0B132F 15.43%, #006BAC 252.83%);
        border-top-left-radius: 15%;
        display: flex;
        flex-direction: column;
        .newListingContentMenu{
            width: 100%;
            height: 20%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            .newListingMenuHeader{
                width: 80%;
                text-align: left;
                color: #FFF;
                font-family: Roboto;
                font-size: 45px;
                font-style: normal;
                font-weight: 900;
                line-height: normal;
                margin: 0px;
                margin-top: 15px;
            }
            .newListingMenuBar{
                height: 60px;
                background-color: #D9D9D940;
                border-radius: 15px;
                margin-top: 5px;
                width: 90%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-evenly;
                padding-left: 5px;
                padding-right: 5px;
                .newListingMenuItem{
                    height: 90%;
                    border-radius: 15px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    padding-left: 15px;
                    padding-right: 15px;
                    cursor: pointer;
                    p{
                        color: #FFF;
                        text-align: center;
                        font-family: Roboto;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 900;
                        line-height: normal;
                        margin: 0px;
                    }
                }
                .newListingMenuItemActive{
                    background-color: #FFFFFF40;
                }
            }
        }

        .newListingContentContent{
            width: 100%;
            height: 80%;
        }
    }
}

.newListingContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}